import { Injectable } from '@angular/core';
import { RideType } from 'src/app/app-v2/types/container.type';

@Injectable({
  providedIn: 'root'
})
export class SelectRideTypeContainerService {

  defaultSelectedRideTypeDetails: RideType;
  selectedRideTypeDetails: RideType;

  constructor() { }

  setSelectedRideTypeDetails(rideTypeDetails){
    this.selectedRideTypeDetails = rideTypeDetails;
    this.defaultSelectedRideTypeDetails = rideTypeDetails;
  }

  getDefaultSelectedRideTypeDetails(){
    return this.defaultSelectedRideTypeDetails;
  }

  getSelectedRideTypeDetails(){
    return this.selectedRideTypeDetails;
  }

  setIsAirportRide(isAirport){
    this.selectedRideTypeDetails.isAirportRide = isAirport;
  }

  getIsAirportRide(){
    return this.selectedRideTypeDetails.isAirportRide;
  }

  setIsSlotRequired(slotRequired){
    this.selectedRideTypeDetails.slotsRequired = slotRequired;
  }

  getIsSlotRequired(){
    return this.selectedRideTypeDetails.slotsRequired;
  }

  setIsPackageRequired(packageRequired){
    return this.selectedRideTypeDetails.packageRequired = packageRequired;
  }

  getIsPackageRequired(){
    return this.selectedRideTypeDetails.packageRequired;
  }
}
