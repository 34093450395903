<div class="main-container">
    
    <div class="left-section">
        
            <card type="container">
                <div class="location-container" container>
                    
                    <div class="title-container">
                        <span class="title">Location</span>
                        <div class="edit-container" (click)="editData('LOCATION')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Location</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="pickup-location-container" *ngIf="rideRequestDetails?.rideDetails?.pickupLocation?.locationName">
                            <div class="icon-container">
                                <img src="assets/pick.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.pickupLocation?.locationName}}</span>
                        </div>

                        <div class="stop-location-container" *ngFor="let stop of rideRequestDetails.stopsList; index as i">
                            <div *ngIf="stop.type == 'STOP'" class="stop-container">
                                <div class="icon-container">
                                    <span>{{i}}</span>
                                </div>
                                
                                <span class="text-info">{{stop.location}}</span>
                            </div>
                        </div>

                        <div class="drop-location-container" *ngIf="rideRequestDetails?.rideDetails?.dropLocation?.locationName">
                            <div class="icon-container">
                                <img src="assets/drop.svg" alt="">
                            </div>
                            
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.dropLocation?.locationName}}</span>
                        </div>
                    </div>
                </div>
            </card>
    

            <card type="container" *ngIf="isPickupNotesContainerRequired">
                <div class="additional-details-container" container>
                    <div class="title-container">
                        <span class="title">Pick up Notes & Business Ride Reason</span>
                        <div class="edit-container" (click)="editData('LOCATION')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Notes</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="pickup-notes-container" *ngIf="rideRequestDetails?.pickupNotes?.notes">
                            <div class="icon-container">
                                <img src="assets/pickupNotes.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.pickupNotes?.notes}}</span>
                        </div>


                        <div class="business-ride-reason-container" *ngIf="rideRequestDetails?.rideDetails?.otherFlags?.businessRideReason">
                            <div class="icon-container">
                                <img src="assets/pickupNotes.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.otherFlags?.businessRideReason}}</span>
                        </div>


                        <div class="expense-code-container" *ngIf="rideRequestDetails?.rideDetails?.otherFlags?.expenseCode">
                            <div class="icon-container">
                                <img src="assets/brrAndExpense.svg" alt="">
                            </div>
                            <span class="text-info">{{rideRequestDetails?.rideDetails?.otherFlags?.expenseCode}}</span>
                        </div>

                    </div>
                </div>        
            </card>
        

            <card type="container">
                <div class="timetamp-container" container>
                    <div class="title-container">
                        <span class="title">Date & Time</span>
                        
                        <div class="edit-container" (click)="editData('TIMESTAMP')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Time</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="time-container">
                        <div class="icon-container">
                            <img src="assets/clock.svg" alt="">
                        </div>
                        <span class="text-info">{{rideRequestDetails.rideDetails.scheduleTimestamp | datetimepipe}}</span>
                    </div>
                </div>
                </div>                
            </card>
        
            <card type="container" *ngIf="isCarVariantContainerRequired">
                <div class="car-variant-container" container >

                    <div class="title-container">
                        <span class="title">Car Variant</span>
                        
                        <div class="edit-container" (click)="editData('CAR_VARIANT')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Package</span>
                        </div>
                    </div>

                    <div class="variants" *ngFor="let variant of carVariantDetails">
                        <div class="variant-details-container">
                            <img [src]="variant.carUrl" alt="">
                            <div class="variant-category-container">
                                <span class="variant-category">{{variant.category}}</span>
                                <span class="variant-label">{{variant.carLabel}}</span>
                            </div>
                        </div>

                            <div class="extra-info-container">
                                <img src="" alt="">
                                <span>{{variant.extraInfo[0]}}</span>
                            </div>
                    </div>
                </div>        
            </card>
    </div>

    <div class="right-section">
        <card type="container">
        <div class="payment-summary-container" container>
            <div class="title-container">
                <span class="title">Payment Summary</span>
            </div>

              <div class="payment-details-container">
                <div class="detail-container">
                    <span class="details">Distance</span>
                    <span class="details">{{(rideRequestDetails.rideDetails.distance / 1000).toFixed(2)}} Km</span>
                </div>

                <div class="detail-container">
                    <span class="details">Fare</span>
                    <span class="details">{{rideRequestDetails.pricingDetails.estimatedFareAmountExclTax | currencypipe}}</span>
                </div>

                <div class="detail-container">
                    <span class="details">Tax</span>
                    <span class="details">{{rideRequestDetails.pricingDetails.estimatedTaxAmount | currencypipe}}</span>
                </div>
              </div>  

              <div class="total-fare-container">
                <span class="total-fare-details">Total Fare</span>
                <span class="total-fare-details">{{rideRequestDetails.pricingDetails.estimatedTotalAmount | currencypipe}}</span>
              </div>        
        </div>
        </card>
        

        <div class="payment-method-container">
            <div class="title-container">
                <span class="title">Payment Method</span>
            </div>
            

            <div class="payment-container">
            <div class="payment-methods" *ngFor="let paymentMode of selectedPaymentModes; index as i" (click)="selectPaymentMode(i)">
                <card type="container" [isActive]="paymentMode.isActive">
                    <div class="mode-container" container>
                        <img [src]="paymentMode['iconUrl']" alt="">
                        <div class="mode-detail-container">
                            <span class="payment-title">{{paymentMode.title}}</span>
                            <span class="payment-desc">{{paymentMode.desc}}</span>
                        </div>
                        
                    </div>
                </card>
            </div>
        </div>
        </div>

        <div class="tnc-container">
            
        </div>

        <div class="confirm-cta-container">
            <custom-button [buttonDetails]="paymentModeCta" (onCustomButtonClick)="submitPaymentMode()"></custom-button>
        </div>
    </div>
</div> 