import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CustomInputType, DropdownDetails, TextAreaDetails, Theme } from 'src/app/app-v2/types/components.type';

@Component({
  selector: 'additional-details-container',
  templateUrl: './additional-details-container.component.html',
  styleUrls: ['./additional-details-container.component.scss']
})
export class AdditionalDetailsContainerComponent implements OnInit {
  additionalDetailsSubscription : Subscription

  pickupNotesDetails: TextAreaDetails = {
    placeholder : "Additional Comments",
    inputValue: '',
    label: "Pickup Notes",
    name: 'pickupNotes',
    validation: null,
    isDisabled: false
  }

  businessRideReasonInputDetails: CustomInputType = {
    placeholder : 'For eg. Late night cab, administrative commute etc',
    type: 'text',
    inputValue: null,
    label: 'Manually add reason',
    name: 'businessRideReason',
    validation:  null,
    isDisabled: false
  }

  expenseCodeInputDetails: CustomInputType = {
    placeholder : 'For eg. Late night cab, administrative commute etc',
    type: 'text',
    inputValue: null,
    label: 'Manually add Expense Code',
    name: 'expenseCode',
    validation:  null,
    isDisabled: false
  }

  businessRideReasonDropDownDetails : DropdownDetails = {
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'Business Ride Reason',
    name: 'businessRideReason',
    placeholder : 'Select Reason',
    isDisabled: false
  }

  expenseCodeDropDownDetails : DropdownDetails = {
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'ExpenseCode',
    name: 'expenseCode',
    placeholder : 'Select Code',
    isDisabled: false
  }

  businessRideReasonsFlags = {
    dropdown:false, textBox: true, enabled: true, mandatory: false, otherRequired: false
  }

  expenseCodeFlags ={
    dropdown: false, textBox: true, enabled: false, mandatory: false, otherRequired: false
  }

  constructor(private _journey: JourneyDetailsContainerService, 
              private _journeyApi: JourneyDetailsApiService,
              private _subscription: CreateRideSubscriptionService,
              private _commonSubscription: CommonSubscriptionService,
              private _loader: NgxSpinnerService){
    
  }

  ngOnInit(): void {
   this.fetchAdditionalDetailsInputDataFromApi();
   this.startAdditionalDetailsSubscription()
  }

  fetchAdditionalDetailsInputDataFromApi(){
    this._loader.show();
    this._journeyApi.fetchReasonAndCode().subscribe((res)=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        this.renderData(res['response'])
      }

      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'OK!'
        })
      }
    })
  }

  renderData(data){
    if(data){
    if(data['businessRideReasonDetails']){
        this.businessRideReasonsFlags['enabled']= data['businessRideReasonDetails']['enabled'];
        this.businessRideReasonsFlags['mandatory'] = data['businessRideReasonDetails']['mandatory'];
        this.businessRideReasonsFlags['otherRequired'] = data['businessRideReasonDetails']['otherRequired'];
        this.businessRideReasonsFlags['dropdown'] = this.checkReasonAndCodeType(data['businessRideReasonDetails']['type'], 'LIST', data['businessRideReasonDetails']['enabled']);
        this.businessRideReasonsFlags['textBox'] = this.checkReasonAndCodeType(data['businessRideReasonDetails']['type'], 'TEXT', data['businessRideReasonDetails']['enabled']);
        this.businessRideReasonDropDownDetails.options = data['businessRideReasonDetails']['itemList'] ? data['businessRideReasonDetails']['itemList'].map(listItem=>{
        return ({label: listItem, value: listItem})
        }) : null;


        this.businessRideReasonsFlags.otherRequired ? this.businessRideReasonDropDownDetails.options.push({label: 'Other', value: 'OTHER'}) : null ;
        this.businessRideReasonInputDetails.label = !this.businessRideReasonsFlags.dropdown? 'Business Ride Reasons' : 'Manually add Reason'
    }

    if(data['expenseCodeDetails']){
      
        this.expenseCodeFlags['enabled']=data['expenseCodeDetails']['enabled'];
        this.expenseCodeFlags['mandatory']=data['expenseCodeDetails']['mandatory'];
        this.expenseCodeFlags['otherRequired']= data['expenseCodeDetails']['otherRequired'];
        this.expenseCodeFlags['dropdown']=this.checkReasonAndCodeType(data['expenseCodeDetails']['type'], 'LIST', data['expenseCodeDetails']['enabled']);
        this.expenseCodeFlags['textBox'] = this.checkReasonAndCodeType(data['expenseCodeDetails']['type'], 'TEXT', data['expenseCodeDetails']['enabled']);
        this.expenseCodeDropDownDetails.options = data['expenseCodeDetails']['itemList'].map(listItem=>{
          return ({label: listItem, value: listItem})
        })

        this.expenseCodeFlags.otherRequired ? this.expenseCodeDropDownDetails.options.push({label: 'Other', value: 'OTHER'}) : null ;
        this.expenseCodeInputDetails.label = !this.expenseCodeFlags.dropdown? 'Expense Code' : 'Manually add Expense Code'

    }
  }
  }

  checkReasonAndCodeType(type, field, isEnabled){
    if(isEnabled){
      return type == field ? true : false;
    }
    else{
      return false
    }
  }


  onDropdownValueChange(type){
    switch(type){
      case 'REASON':

        this.businessRideReasonsFlags.textBox = this.businessRideReasonDropDownDetails.selectedValue.value == 'OTHER' ? true : false
        return 
    

      case 'CODE':
        this.expenseCodeFlags.textBox = this.expenseCodeDropDownDetails.selectedValue.value == 'OTHER' ? true : false
        return
    } 
  }


  sendAdditionalDetailsInputsData(){
    let additionalDetails = {
      otherFlags :{
        businessRideReason: this.businessRideReasonsFlags.textBox ? this.businessRideReasonInputDetails.inputValue : this.businessRideReasonDropDownDetails.selectedValue.value,
        expenseCode: this.expenseCodeFlags.textBox ? this.expenseCodeInputDetails.inputValue : this.expenseCodeDropDownDetails.selectedValue.value
      },
      pickupNotes:this.pickupNotesDetails.inputValue  == '' ? null : this.pickupNotesDetails.inputValue
    }
    this._journey.setAddtionalDetails(additionalDetails.otherFlags, additionalDetails.pickupNotes);
  }

  startAdditionalDetailsSubscription(){
    this.additionalDetailsSubscription = this._subscription.fetchTriggerLocationCtaObserver().subscribe((res)=>{
      this.sendAdditionalDetailsInputsData();
    })
  }
}
