<div class="main-container">
    <div class="time-stamp-container">
        <custom-input [inputBoxDetails]="timestampInputDetails" [inputType]="'CALENDAR'" (onSelectDate)="setTimestamp($event)" (onDatePanelVisible)="panelVisible()"></custom-input>
    </div>


    <div class="slots-container" *ngIf="isSlotsRequired">
        <div class="hours-container">
            <dropdown [dropdownDetails]="hourDropDownDetails" (changehandler)="onHoursChange()"></dropdown>
        </div>

        <div class="min-container">
            <dropdown [dropdownDetails]="minDropDownDetails"></dropdown>
        </div>
    </div>

    <div class="time-stamp-cta-container">
        <custom-button [buttonDetails]="timeStampContainerCta" (onCustomButtonClick)="timeStampCtaClick()"></custom-button>
    </div>   
</div>