import { Pipe, PipeTransform } from '@angular/core';
import { CountryFeatureListService } from '../localization/country-feature-list/country-feature-list.service';

@Pipe({
  name: 'createridepipe'
})
export class CreateridepipePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}


@Pipe({
  name: 'datetimepipe'
})
export class DateTimePipe implements PipeTransform{
    transform(value: any, ...args: any[]): any {
      if (!value) {
        return '';
      }
      
      let localDate = new Date(value);
      const formattedDate = localDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short', // Short month name (e.g., Aug)
        day: '2-digit', // Two-digit day (e.g., 29)
        hour: '2-digit', // Two-digit hour (e.g., 11)
        minute: '2-digit', // Two-digit minute (e.g., 43)
        second: '2-digit', // Two-digit second (e.g., 43)
        hour12: true, // Use 12-hour clock (AM/PM)
      });
      value = formattedDate
      
      const datesVal = value.split(',');
      let date = datesVal[0];
      let year = datesVal[1];
      let time = datesVal[2] ? datesVal[2] : datesVal[1].split("at")[1];
      let splittedTime = time.split(':');
      splittedTime[2]= splittedTime[2].slice(-2);
      
      return date+',' +splittedTime[0]+':' +splittedTime[1] +' ' +splittedTime[2];
    }
  }

  @Pipe({
    name: 'currencypipe'
  })
  export class CurrencyPipe implements PipeTransform{
    constructor(private _countryFeature: CountryFeatureListService){}

    transform(value: any, ...args: any[]) {
      let selectedCurrency  = this._countryFeature.getCountryFeatureList()['defaultCurrency'];

      if(selectedCurrency['type'] == 'PREFIX'){
        return `${selectedCurrency['code']}${value}`;
      }
      else{
        return `${value}${selectedCurrency['code']}`;
      }
    }
    
  }
