import { Component, OnDestroy, OnInit } from '@angular/core';
import { EditableContainerComponent } from '../../components/editable-container/editable-container.component';
import { CustomButton, EditableContainerType } from '../../types/components.type';
import { PaymentDetailsContainerService } from '../../getter-setter/container/create-ride/payment-details-container.service';
import { RequestRideApiResponse } from '../../types/apis.type';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { CommonService } from '../../getter-setter/common.service';
import { PaymentModesService } from '../../getter-setter/container/payment-modes/payment-modes.service';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SelectRideTypeContainerService } from '../../getter-setter/container/create-ride/select-ride-type-container.service';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';

@Component({
  selector: 'payment-details-container',
  templateUrl: './payment-details-container.component.html',
  styleUrls: ['./payment-details-container.component.scss']
})
export class PaymentDetailsContainerComponent implements OnInit, OnDestroy{
rideRequestDetails: RequestRideApiResponse;
closeDialogSubscription: Subscription;

carVariantDetails = [];
paymentModes;
selectedPaymentModes = [];
selectedPayment = "";

isCarVariantContainerRequired = false;
isPickupNotesContainerRequired = false;

paymentModeCta:CustomButton  = {
    label: 'Confirm Booking',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: true
}

constructor(private _payment: PaymentDetailsContainerService,
            private _paymentMode: PaymentModesService,
            private _createRide: CreateRideService,
            private _common: CommonService,
            private _subscription : CreateRideSubscriptionService,
            private _commonSubscription: CommonSubscriptionService,
            private _rideType: SelectRideTypeContainerService,
            private _router: Router
){}

ngOnInit(): void {
  this.rideRequestDetails = this._payment.getRideRequestDetails();
  this.carVariantDetails = this._common.getAllCarvariantDetails();
  this.paymentModes = this._payment.getAllowedPaymentModesDetails();
  this.isCarVariantContainerRequired = this._rideType.getIsAirportRide() || this._rideType.getIsPackageRequired() ? true : false;
  
  this.isPickupNotesContainerRequired = this.checkIfPickupNotesContainerRequired(); 
  this.getSelectedPaymentModes();
  this.startCloseDialogSubscription();
  this.checkActivePaymentMode();
}

checkIfPickupNotesContainerRequired(){
  let otherFlagsDetails = this.rideRequestDetails.rideDetails.otherFlags;
  let pickupNotes = this.rideRequestDetails.pickupNotes.notes == null || this.rideRequestDetails.pickupNotes.notes == '' ? false : true;
  let businessRideReason = otherFlagsDetails.businessRideReason == null || otherFlagsDetails.businessRideReason == '' ? false : true;
  let expenseCode = otherFlagsDetails.expenseCode == null || otherFlagsDetails.expenseCode == '' ? false : true;

  if(pickupNotes || businessRideReason || expenseCode){
    return true;
  }
  else{
    return false
  }
}

getSelectedPaymentModes(){
  this.selectedPaymentModes = Object.keys(this.paymentModes).map(paymentMode=>{
    return this.paymentModes[paymentMode];
  })
}

selectPaymentMode(index){
  this.selectedPaymentModes.forEach(mode => {
    mode.isActive = false;
  });

  this.selectedPaymentModes[index].isActive = true;
  this.selectedPayment = this.selectedPaymentModes[index].code;
  this._createRide.setPaymentMode(this.selectedPayment ? this.selectedPayment : 'BUSINESS_ACCOUNT');
  this.checkActivePaymentMode();
}


submitPaymentMode(){
this._paymentMode.setSelectedPaymentMode(this.selectedPayment);
}


startCloseDialogSubscription(){
  this.closeDialogSubscription = this._commonSubscription.fetchCloseAlert().subscribe(res=>{
    if(res){
      this._router.navigate(["/rides/schedulerides"])
    }
  })
}

checkActivePaymentMode(){
  let activePaymentMode = Object.keys(this.paymentModes).map(paymentMode=>{
    if(this.paymentModes[paymentMode]['isActive']){
      this.selectedPayment = this.paymentModes[paymentMode]['code'];
      return this.paymentModes[paymentMode]['isActive'];
    }
    else{
      return this.paymentModes[paymentMode]['isActive'];
    }
  });
  this.paymentModeCta.disabled = activePaymentMode.includes(true) ? false : true;
}


editData(type){
    this._subscription.emitEditRideDataSubscription(type);
}

ngOnDestroy(): void {
  this.closeDialogSubscription.unsubscribe();
}


}
