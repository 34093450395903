import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateRidePageComponent } from './create-ride-page/create-ride-page.component';
import { ContainerModule } from '../containers/container.module';
import { SelectRideTypePageComponent } from './select-ride-type-page/select-ride-type-page.component';
import { ComponentsModule } from '../components/components.module';
import { CountryFeatureListService } from '../localization/country-feature-list/country-feature-list.service';
import { CreateSharedRidePageComponent } from './create-shared-ride-page/create-shared-ride-page.component';
import { DutyModule } from 'src/app/main/container/duty/duty.module';




@NgModule({
  declarations: [CreateRidePageComponent, SelectRideTypePageComponent, CreateSharedRidePageComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ContainerModule,
    DutyModule
],
  exports: [CreateRidePageComponent, SelectRideTypePageComponent]
})
export class PagesModule { }
