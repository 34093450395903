import { ButtonModule } from 'primeng/button';
import { CommonsModule } from './../../common/common.module';
import { DutyService } from './../../../services/duty/duty.service';
import { DutyRoutingModule } from './duty-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CreateNewRide } from './component/create-new-ride.component';


import { GooglePlaceModule } from 'ngx-google-places-autocomplete'; 
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormsModule } from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import { AgmDirectionModule } from 'agm-direction';
import { AgmCoreModule, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { CalendarModule } from 'primeng/calendar';
import { ReactiveFormsModule}  from '@angular/forms';
// import { GPlaceInputComponent } from './component/g-place-input/g-place-input.component';
import { RidesModule } from '../rides/rides.module';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {AccordionModule} from 'primeng/accordion';
import {DialogModule} from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { GPlaceInputComponent } from './component/g-place-input/g-place-input.component';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { LocationService } from 'src/app/services/location/location.service';
import { BusinessDetailsService } from 'src/app/services/business-details/business-details.service';
import { RideService } from 'src/app/services/ride/ride.service';
import { RiderService } from 'src/app/services/rider/rider.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UsersService } from 'src/app/services/users/users.service';





@NgModule({
  declarations: [CreateNewRide, GPlaceInputComponent],
  imports: [
    CommonModule,
    DutyRoutingModule,
    NgbModule,
    FormsModule,
    RadioButtonModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    SliderModule,
    CommonsModule,
    ButtonModule,
    InputTextModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot(
      {
        apiKey:environment.googlePlacesAPIKey,
        libraries: ['places']
      }
    ),
    AgmDirectionModule,
    CalendarModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    AccordionModule,
    DialogModule,
    InputTextareaModule
  ],
  providers:[GoogleMapsAPIWrapper, 
              FormBuilder,
              DatePipe,
              NgbModal,
              NgbActiveModal
            ],

  exports:[CreateNewRide, GPlaceInputComponent]
 
 
})
export class DutyModule { }
