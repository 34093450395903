import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { ContainerModule } from './containers/container.module';
import { GetterSetterModule } from './getter-setter/getter-setter.module';
import { CreateRideApiService } from './api-services/create-ride/create-ride-api.service';
import { PagesModule } from './pages/pages.module';
import { CommonService } from './getter-setter/common.service';
import { SelectRideTypeContainerService } from './getter-setter/container/create-ride/select-ride-type-container.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CountryFeatureListService } from './localization/country-feature-list/country-feature-list.service';
import { CreateridepipePipe } from './pipe/createridepipe.pipe';



@NgModule({
  declarations: [
    CreateridepipePipe
  ],
  imports: [
    CommonModule,
    PagesModule,
    GetterSetterModule,
    NgxSpinnerModule
  ],
  providers: [CreateRideApiService, CommonService, SelectRideTypeContainerService, CountryFeatureListService],
  exports: [PagesModule]

})
export class AppV2Module { }
