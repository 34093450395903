<div class="main-container">
    <div class="header-container" (click)="toggleAccordian()">
        <div class="head-title-container">
        <img src="assets/complete-badge.svg" alt="" *ngIf="accordianDetails.isComplete">
        <span class="acc-title">{{accordianDetails?.label}}</span> 
        </div>

        <span>
            <img src="assets/arrow.svg" alt="">
        </span>
    </div>
    <!-- *ngIf="accordianDetails?.isActive"  -->
    <div class="body-container" *ngIf="accordianDetails.isActive">
        <div class="acc-title">
            <ng-content select="[accBody]"></ng-content>
        </div>
    </div>
</div>