import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { RideType, RideTypeExportDto } from '../../types/container.type';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { EventManager } from '@angular/platform-browser';
import { CommonService } from '../../getter-setter/common.service';
import { SelectRideTypeContainerService } from '../../getter-setter/container/create-ride/select-ride-type-container.service';
import { Subscription } from 'rxjs';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { Router } from '@angular/router';

@Component({
  selector: 'select-ride-type-container',
  templateUrl: './select-ride-type-container.component.html',
  styleUrls: ['./select-ride-type-container.component.scss']
})
export class SelectRideTypeContainerComponent implements OnDestroy{
rideTypes: RideType[];


constructor(private _common: CommonService, 
            private _router: Router,
            private _createRide: CreateRideService,
            private _selectRideType: SelectRideTypeContainerService){

this.rideTypes = _common.getAllowedRideTypesDetails();
}

setSelectedRideType(index){
  this.rideTypes[index]['isActive'] = !this.rideTypes[index]['isActive'];
    this._createRide.clearRideRequestDto();
    this._createRide.clearCreateRideAllSteps();
    this._selectRideType.setSelectedRideTypeDetails(this.rideTypes[index]);
    this._createRide.setSelectedRideType(this.rideTypes[index].code);

    this._router.navigate(this.rideTypes[index].code == 'SHARED_RIDE' ?["/sharedride"] : ["/create"]);
}

ngOnDestroy(): void {
  this.rideTypes.forEach(rideType=>rideType.isActive = false);
}

}
