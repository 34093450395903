<div class="login-page " style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;overflow-x:hidden;">
    <div style="display: flex; height: 100%;width: 100%;">
      
        <div style="width: 50%; background-color: #E7F3FF ;" class="heroImage">
          <!-- <img src="../../../assets/logoBlue.png" width="70px" style="margin: 5px;"/> -->
          <!-- <img src="../../../assets/heroImage.png" style="" alt=""> -->
        </div>
        
        <div style="background: white; width: 50%;" class="container">
          <div style="height: 100%;width: 100%;padding: 10%;" class="container">
                <div  class="container">
                    <div style="align-items: center;text-align: center;margin-top: 10%;margin-bottom: 0%;" >
                        <img src="../../../assets/logoBlue.png" width="100px" >
                    </div>
                    <br/>
                    <div style="align-items: center;text-align: center;color: #3E4958;" >
                       <h3> Let's get started</h3>
                    </div>

                    <div style="padding: 10%;" class="container">
                        <form #f='ngForm' (ngSubmit)='login(f)' autocomplete="off">
                        <!-- <div style="color: #3E4958;margin-bottom: 10px;" class="row"> 
                            Username
                        </div> -->
                       
                        <div class="input-group mb-3" class="row">
                            <!-- <span class="p-float-label" style="width: 100%;">
                            <input pInputText type="text" name="username" class="form-control customFormInput" style="padding-left: 15px;" id="username" ngModel />
                            <label for="username" style="color: #3E4958;">Username</label> -->

                            <span class="p-float-label" style="width: 100%;">
                                <input pInputText type="text" name="username" class="form-control customFormInput" style="padding-left: 15px;" id="username" ngModel/>
                                <label htmlFor="username" style="color: #3E4958;">Username</label>
                            </span>
                        <!-- </span> -->
                        </div>
                        
                        <br/>
                        <br/>

                        <!-- <div style="color: #3E4958;margin-bottom: 10px;" class="row">
                            Password
                        </div> -->
                        <div class="input-group mb-3" class="row">
                            <span class="p-float-label" style="width: 100%;">
                            <input pInputText type="password" name="password" class="form-control input_pass customFormInput" [ngStyle]="{border: loginError == null? '1px solid #B4B4BB' : '1px solid red' }" style="padding-left: 15px;" id="password" ngModel >
                            <label htmlFor="password" style="color: #3E4958;">Password</label>
                            <span class="loginError">{{loginError}}</span>
                            </span>
                        </div>

                        <div class="form-group" class="row" style="margin-top: 10px;">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customControlInline">
                                <label class="custom-control-label" for="customControlInline" style="text-align: left;align-items: left; background: white;">Remember me</label>
                            </div>
                        </div>
                        <br/>
                        <div class="d-flex justify-content-center mt-3 login_container" style="text-align: center;align-items: center;" >
                           <button type="submit" name="button" class="btn login_btn" style="background: #2C66E3;height: 50px;box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);border-radius:4px ; width: 80%;" [disabled]="disableLoginButton">Login</button>
                            <span (click)="changePassword()" class="forgotPwd">Forgot Password?</span>
                        </div>
                          </form>
                    </div>

                </div>
          </div>
        </div>
        
      </div>
</div>