import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Button } from 'primeng/button';
import { CustomButton } from '../../types/components.type';

@Component({
  selector: 'custom-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() buttonDetails:CustomButton;
  @Output() onCustomButtonClick = new EventEmitter<string>()



  onClick(){
    this.onCustomButtonClick.emit('click');
  }
}
