import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepperData } from '../../types/components.type';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
@Input() stepperData: StepperData[];
@Input() align: string
@Output() fetchStepLabel:EventEmitter<string> = new EventEmitter();

constructor(){

}

fetchLabel(index){
  if(this.stepperData[index].isComplete || this.stepperData[index-1].isComplete){
    this.fetchStepLabel.emit(this.stepperData[index].label)
  }
  else{
    return "";
  }
}
}
