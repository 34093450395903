import { Component, OnDestroy, OnInit } from '@angular/core';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { CreateRideSteps, JourneyDetails, JourneyDetailsStep, RideType } from '../../types/container.type';
import { SelectRideTypeContainerService } from '../../getter-setter/container/create-ride/select-ride-type-container.service';
import { Subscription } from 'rxjs';
import { JourneyDetailsContainerService } from '../../getter-setter/container/create-ride/journey-details-container.service';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';

@Component({
  selector: 'journey-details-container',
  templateUrl: './journey-details-container.component.html',
  styleUrls: ['./journey-details-container.component.scss']
})
export class JourneyDetailsContainerComponent implements OnInit, OnDestroy{
    triggerLocationSubscription: Subscription;
    triggerCarVariantSubscription: Subscription;
    isAirportRideSubscription: Subscription;
    editJourneyDetailsSubscription: Subscription;
    
    journeyDetails: JourneyDetails;
    selectedRideType: RideType;
    
  constructor(private _rideType: SelectRideTypeContainerService,
              private _subscription: CreateRideSubscriptionService,
              private _journey: JourneyDetailsContainerService){
  }
  
  ngOnInit(): void {
    
    this.journeyDetails= {
      locationDetails : this._journey.getLocationAccDetails(),
      carVariantDetails : this._journey.getCarvariantAccDetails(),
      timestampDetails : this._journey.getTimestampAccDetails()
    };

    this.startAirportRideSubscription();
    this.startLocationCtaSubscription();
    this.startCarVariantCtaSubscription();
    this.selectedRideType = this._rideType.getSelectedRideTypeDetails();
    this.setActiveAccordian(JourneyDetailsStep.LOCATION_DETAILS);
    
  }


  startAirportRideSubscription(){
    
    this.isAirportRideSubscription = this._subscription.fetchIsAirportRideObserver().subscribe((res)=>{
        this._rideType.setIsAirportRide(res);
        this._rideType.setIsSlotRequired(res);
        this.journeyDetails.carVariantDetails.isVisible = this.selectedRideType.packageRequired ? true : res;   
    });
  }

  startLocationCtaSubscription(){
    this.triggerLocationSubscription = this._subscription.fetchTriggerLocationCtaObserver().subscribe(res=>{

      if(res){
        this._subscription.emitPackageObserver(res);
        
        if(this.selectedRideType.packageRequired){
          this.setActiveAccordian(JourneyDetailsStep.CAR_DETAILS);
          this.journeyDetails.locationDetails.isComplete = true;
          return ;
        }

        else{
          this.setActiveAccordian(this._rideType.getIsAirportRide() ? JourneyDetailsStep.CAR_DETAILS : JourneyDetailsStep.TIMESTAMP);
          this.journeyDetails.locationDetails.isComplete = true;
          return ;
        }
      }

      else{
        this.journeyDetails.locationDetails.isComplete = false;
        return ;
      }
    });
  }

  startCarVariantCtaSubscription(){
    this.triggerCarVariantSubscription = this._subscription.fetchTriggerCarVariantCtaObserver().subscribe(res=>{
      if(res){
        this._subscription.emitIsSlotRequired(this._rideType.getDefaultSelectedRideTypeDetails().slotsRequired ? true : this._rideType.getIsSlotRequired());
        this.setActiveAccordian(JourneyDetailsStep.TIMESTAMP);
        this.journeyDetails.carVariantDetails.isComplete = true;
      }
      else{
        this.journeyDetails.carVariantDetails.isComplete = false;
      }
    });
  }


  toggleActiveAcc(res, activeStep){
    Object.keys(this.journeyDetails).forEach(key=>this.journeyDetails[key]['isActive']=false);
    this.journeyDetails[activeStep]['isActive'] = res;
    
    if(this.journeyDetails.carVariantDetails.isVisible){
      this._subscription.emitPackageObserver(this.journeyDetails.carVariantDetails.isActive);
    }
  }

  setActiveAccordian(activeAccordian){
    Object.keys(this.journeyDetails).forEach(step=>{
      this.journeyDetails[step].isActive  = false;
    })

    this.journeyDetails[activeAccordian]['isDisabled'] = false;
    this.journeyDetails[activeAccordian]['isActive'] = true;
  }


  startEditJourneyDetailsSubscription(){
    this.editJourneyDetailsSubscription = this._subscription.fetchEditJourneyDetails().subscribe(res=>{
      console.log(res);
      switch(res){
        case 'LOCATION':
          this.setActiveAccordian(JourneyDetailsStep.LOCATION_DETAILS);
          return;
        case 'CAR_VARAIANT':
          this.setActiveAccordian(JourneyDetailsStep.CAR_DETAILS);
          return;
        case 'TIMESTAMP':
          this.setActiveAccordian(JourneyDetailsStep.TIMESTAMP);
          return;
        default:
          this.setActiveAccordian(JourneyDetailsStep.LOCATION_DETAILS);
          return;
      }

    })
  }

  ngOnDestroy(){
    this.triggerLocationSubscription.unsubscribe()
    this.triggerCarVariantSubscription.unsubscribe();
    this.isAirportRideSubscription.unsubscribe()
  }
}


