import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  AfterViewInit
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BusinessDetailsService } from "src/app/services/business-details/business-details.service";
import { PaymentService } from 'src/app/services/payment/payment.service';
import Swal from "sweetalert2";
import {API_URL_REDIRECT_TO_RAZORPAY} from '../../../../config/apiRouteConfig/index'
import { AppService } from "src/app/services/app.service";



@Component({
  selector: "app-addmoney",
  templateUrl: "./addmoney.component.html",
  styleUrls: ["./addmoney.component.css"]
})
export class AddMoneyComponent implements OnInit, AfterViewInit {
 constructor(private ngbActiveModal: NgbActiveModal, private _paymentService: PaymentService,private businessDetailsService: BusinessDetailsService, private _appService:AppService){
  
 }
 amountEntered:number;
 disabledButton:boolean=true;
 newWindow:Window;
 timerId=null;
 interval:any;
 paymentid=null;
 walletBalance:string= localStorage.getItem('walletBalance');
 ngOnInit(){}

 keepEye(window:Window){
  
  this.interval = setInterval(()=> {
     if(window.closed){
      //  console.log("closed");
       this._paymentService.checkStatus(this.paymentid).subscribe((response:any)=>{
         if(response.status>=300){
          Swal.fire({
            title: 'Error',
            icon: 'info',
            text: response.message
          });
          clearInterval(this.interval); 
         }
         if(response.response=='COMPLETED'){
           let message= (this._appService.getCountry() == 'IN' ?  "₹" +this.amountEntered   : this.amountEntered  +'د.إ' )+ " has been added to business wallet."
          Swal.fire({
            title: '<span style="color:#5DCF5E">Payment Successful</span>',
            // icon: 'info',
            imageUrl:'../../../../../assets/addMoneySuccess.png',
            text: message
          });
          let previousBalance = this.businessDetailsService.getWalletBalamce();
          let newBalance = parseInt(previousBalance) + this.amountEntered;
          this.businessDetailsService.setWalletBalance(newBalance);
          const modal = this.ngbActiveModal.close('success')
          clearInterval(this.interval); 
         }else {
          Swal.fire({
            title: 'Info',
            icon: 'info',
            text: "Transaction is in Status: "+ response.response
          });
          clearInterval(this.interval); 
         }
       })
       
     }
},1000);
  
 }

//  ngOnDestroy(){
//    if(this.interval){
//      clearInterval(this.interval);
//    }
//  }


 ngAfterViewInit(){

 }

 disableButton(){
  if(this.amountEntered>0){
    this.disabledButton = false;
    
  }else {
    this.disabledButton = true;
  }
    

    
 }

 close() {
  this.ngbActiveModal.close();
}

checkPaymentStatus(paymentId)
{

}

//  timer = setInterval(function () {
//   if (this.newWindow.closed) {
//       alert("closed");
//       clearInterval(this.timer);
     
//   }
// }, 1000);


addMoneyToWallet(){
  if(this.amountEntered>100000){
    Swal.fire({
      title: 'Error',
      icon: 'info',
      text: "Please enter amount less than or equal to 1,00,000"
    });
    return;
  }
  this._paymentService.addMoneyToWallet(this.amountEntered).subscribe((response:any)=>{
    if (response.statusCode >= 300) {

      Swal.fire({
        title: 'Error',
        icon: 'info',
        text: response.message
      });
    } else {
       this.paymentid= response.response;
      // this.ngbActiveModal.close();
      //  this.newWindow = window.open(API_URL_REDIRECT_TO_RAZORPAY+paymentid+'?phoneNo='+localStorage.getItem('phoneNo') +'&email='+localStorage.getItem('email')+'','_blank');
       this.newWindow = window.open(API_URL_REDIRECT_TO_RAZORPAY+this.paymentid+'?phoneNo='+localStorage.getItem('phoneNo') +'&email='+localStorage.getItem('email')+'',"Add Money","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=300,height=300,left = 490,top = 262");
       this.keepEye(this.newWindow);
        
     
    }
  })
}

}
