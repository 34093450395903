import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ModalComponent } from './modal/modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe, DateTimePipe, OnlyDatePipe, CurrencySymbolPipe, PaymentModeSetter } from './pipes/pipe.pipe';
import { TimeDifference } from './pipes/pipe.pipe';
import {MaskNumber} from './pipes/pipe.pipe'
import { RidePanelComponent } from './ride-panel/ride-panel.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
// import { GMapModule} from 'primeng/gmap'
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RatingModule} from 'primeng/rating';
import { RideService } from 'src/app/services/ride/ride.service';
import { FormsModule } from '@angular/forms';
import { PaymentPanelComponent } from './payment-panel/payment-panel.component';
import { PaymentHistoryPanelComponent } from './payment-history-panel/payment-history-panel.component';
import { RiderPanelComponent } from './rider-panel/rider-panel.component';
import { OverspeedingPanelComponent } from './overspeeding-panel/overspeeding-panel.component';
import { PickupNotesPanelComponent } from './pickup-notes-panel/pickup-notes-panel.component';;
import {DialogModule} from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import {SearchFiltersComponent}  from './search-filters/search-filters.component'
import {TableComponentCustom} from './table/table.component'
import { environment } from '../../../environments/environment'


@NgModule({
  declarations: [ModalComponent, DatePipe,DateTimePipe,CurrencySymbolPipe,PaymentModeSetter, OnlyDatePipe, TimeDifference, RidePanelComponent, PaymentPanelComponent, PaymentHistoryPanelComponent, RiderPanelComponent, OverspeedingPanelComponent, PickupNotesPanelComponent,SearchFiltersComponent,TableComponentCustom ],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlacesAPIKey
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),ProgressSpinnerModule,RatingModule,FormsModule, TableModule, ButtonModule,DialogModule,
    TooltipModule
  ],providers:[GoogleMapsAPIWrapper,RideService],
  exports: [ModalComponent, DatePipe,DateTimePipe,CurrencySymbolPipe,PaymentModeSetter, OnlyDatePipe, TimeDifference, RidePanelComponent,PaymentPanelComponent, PaymentHistoryPanelComponent,RiderPanelComponent,OverspeedingPanelComponent,PickupNotesPanelComponent,SearchFiltersComponent,TableComponentCustom]
})
export class CommonsModule { }
