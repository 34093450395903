<div class="dropDown-container">
    <span class="field-label">{{dropdownDetails ? dropdownDetails.label : null}}</span>

    <div class="dropdown-container-basic" [ngClass]="{'dropdown-error' : dropdownDetails?.validation?.isError, 'dropdown-success' : dropdownDetails?.validation?.isSuccess, 'dropdown-disabled' : dropdownDetails?.isDisabled}">
        <p-dropdown [options]="dropdownDetails.options" optionLabel="label" [(ngModel)]="dropdownDetails.selectedValue" [showClear]="false" [placeholder]="dropdownDetails.placeholder" (onChange)="handleChangeEvent()"
        appendTo="body" [disabled]="dropdownDetails?.isDisabled">
            
        <ng-template let-items pTemplate="item">
                <div class="item-container">
                    <span class="main-text">{{items?.label}}</span>
                    <span class="additional-text">{{items?.additionaltext}}</span>
                </div>
            </ng-template>
        </p-dropdown>    

    </div>


        <div class="validator-container" *ngIf="dropdownDetails?.validation != null">
        <validator [validatorDetails]="dropdownDetails?.validation"></validator>
        </div>

</div>