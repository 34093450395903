import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { mapStyles } from './mapStyles';
import { CommonService } from '../../getter-setter/common.service';

@Component({
  selector: 'custom-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges{

  

  @Input() isDropRequired:boolean;
  @Input() agmCoordinates = {
    pick : this._common.getDefaultAgmCoordinates(),
    stops: [{location: this._common.getDefaultAgmCoordinates(), stopover: false}],
    destination : this._common.getDefaultAgmCoordinates(),
  }

  @Input() isVisible= {
    pick: false,
    stops: false,
    drop: false
  }



  agmCredentials ={
    renderOptions : { suppressMarkers: true, polylineOptions: { strokeColor: '#2C66E3' ,strokeWeight: 3}}, // suppressMarker hide the default markers for pick and drop, polylineoptions set path styling
    
    pick : this._common.getDefaultAgmCoordinates(), // set lat lng for pickup location
    
    destination :  this._common.getDefaultAgmCoordinates(),// set lat lng for destination location
    
    stops: [{location: this._common.getDefaultAgmCoordinates(), stopover: false}],
    
    markerOptions : {"origin": this.getMarkerOptionsDto('origin', this.isVisible.pick),
                     "destination": this.getMarkerOptionsDto('destination', this.isVisible.drop),
                     "waypoints": this.getMarkerOptionsDto('waypoints', this.isVisible.stops)        
                    } ,
    options : mapStyles
    } 



  constructor(private _common: CommonService){ 
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.updateAgmCredentials();
  }


  updateAgmCredentials(){

    if(!this.isDropRequired){
      this.agmCredentials.markerOptions.destination.visible = false;
    }

    else{
      console.log(this.agmCoordinates);
      this.agmCredentials.pick = this.agmCoordinates.pick;
      this.agmCredentials.stops = this.agmCoordinates.stops;
      this.agmCredentials.destination = this.agmCoordinates.destination;

      
      this.agmCredentials.markerOptions ={
        "origin": this.getMarkerOptionsDto('origin', this.isVisible.pick),
        "destination": this.getMarkerOptionsDto('destination', this.isVisible.drop),
        "waypoints": this.getMarkerOptionsDto('waypoints', this.isVisible.stops)        
       }
    }
  }


  getMarkerOptionsDto(type, showMarker){ 
    let urls = {origin : '../../../assets/pick-location-icon.svg',
                destination:'../../../assets/drop-location-icon.svg',
                waypoints: '../../../assets/stops-icon.svg'};
                
     let width= '10px';
     let height= '10rem';  
     let isVisible = showMarker;


     if(isVisible == false && this.agmCredentials){
     if(type == 'waypoints'){
      this.agmCredentials.stops = [{location: this._common.getDefaultAgmCoordinates(), stopover: false}]
     } 
     else if(type == 'origin'){
      this.agmCredentials.pick = this._common.getDefaultAgmCoordinates();
     }
     else{
      this.agmCredentials[type] = this._common.getDefaultAgmCoordinates();
     }
     }
     return {
      icon: urls[type],
      width: width,
      height : height,
      visible: isVisible
     }
    }


}




