import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PopupType } from '../../types/components.type';
import { PopUpComponent } from '../../components/pop-up/pop-up.component';

@Injectable({
  providedIn: 'root'
})
export class CommonSubscriptionService {
  popupTrigger = new Subject<PopupType>();
  closeDialog = new Subject<boolean>();

  constructor() { 
  }

  emitAlert(popupDetails: PopupType){
    this.popupTrigger.next(popupDetails);
  }

  fetchAlert(){
   return  this.popupTrigger.asObservable();
  }

  emitCloseAlert(isSuccess){
    this.closeDialog.next(isSuccess);
  }

  fetchCloseAlert(){
   return  this.closeDialog.asObservable();
  }
}
