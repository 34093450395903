export const featureList = {
    
        defaultActiveRideTypes : [
            {name: 'Scheduled Ride', code: 'SCHEDULED_RIDE', isActive: false, isRecurring: false, url: "assets/scheduled_ride.png", packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: false},
            {name: 'Rental Rides', code: 'SCHEDULED_RENTALS', isActive: false, isRecurring: false, url: "assets/scheduled_rentals.png", packageRequired: true, isDropRequired: false, isAirportRide: false, slotsRequired: false },
            {name: 'Airport Ride', code: 'SCHEDULED_RIDE', isActive: false, isRecurring: false, url: "assets/scheduled_ride.png", packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: false},
            {name: 'Shared Rides', code: 'SHARED_RIDE', isActive: false, isRecurring: false, url: "assets/shared_rides.png",  packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: false},
            // {name: 'Recurring Rentals', code: 'RECURRING_RENTALS', isActive: false, isRecurring: true, url: "assets/recurring_rentals.png",  packageRequired: true, isDropRequired: false, isAirportRide: false, slotsRequired: false},
          ],

        carVariants : [
            {carUrl : 'assets/classic.png',
              carLabel: 'Tata Tigor Express T', 
              category: 'CLASSIC', 
              extraPoints: [
              {url: 'assets/luggage.svg', label: 'More luggage Space'}, 
              {url: 'assets/comfort.svg', label: 'Lavish comfort'}, 
              {url: 'assets/leg.svg', label: 'More leg space'}
              ], 
              priceMapId : 0,
              slotsRequired: false,
             country: 'IN', 
             isActive: false,
            extraInfo: ['Switch to classic and enjoy an economical ride ']},
        
        //2  
            {carUrl : 'assets/premium.png', 
            carLabel: 'MG Hector | BYD',
            category: 'PREMIUM',
            slotsRequired: true,
            extraPoints: [
              {url: 'assets/luggage.svg', label: 'More luggage Space'},
              {url: 'assets/comfort.svg', label: 'Lavish comfort'},
              {url: 'assets/leg.svg', label: 'More leg space'}
              ],
             priceMapId : 0,
             country: 'IN',
             isActive: false,
            extraInfo: ['Upgrade to premium in just ₹200',  'Premium slots not available for your selected date and time']
          }],

        defaultCoordinates: { lat: 28.4950, lng: 77.0895 },

        defaultPaymentModes:{
          'CASH' : {isActive: true, iconUrl: 'assets/cash_icon.svg', desc: 'pay at the end of the ride', title: 'Cash', code: 'CASH'},
          'BUSINESS_ACCOUNT':{isActive: false, iconUrl: 'assets/business_wallet_icon.svg', desc: 'pay in advance seamlessly', title: 'Business Wallet', code: 'BUSINESS_ACCOUNT'},
          'CARD': {isActive: false, iconUrl: 'assets/card_icon.svg', desc: 'Pay through debit or credit card', title: 'Card', code: 'CREDIT_CARD'}
        },

        defaultCallingCode: {
          label: 'India',
          value: '+91',
          flag: "🇮🇳",
          limit: 10
        },

        defaultCurrency : {
          code: '₹',
          type: 'PREFIX'
        },

        zoneIdBasedLeadTime : {
          1 : 30, 
          2: 60
        }

}