import { API_URL_ALL_PAYMENTS_TRANSACTIONS, API_URL_ALL_RIDER_BALANCE, API_URL_PAYMENT_ORDER, API_URL_ALL_BLUWALLET_TRANSACTIONS, API_URL_BLUWALLET_PANEL,
  API_URL_ADD_MONEY_TO_WALLET,API_URL_CHECK_ADD_MONEY_STATUS,API_URL_FETCH_INVOICE_DATA, API_URL_CREDIT_HISTORY, API_URL_ADD_CREDIT,API_URL_CURRENT_BALANCE, API_URL_ADD_MONEY_TO_WALLET_TELLER, API_URL_PREPAID_TRANSACTIONS, API_URL_FETCH_PENDING_PAYMENTS, API_URL_GET_PAYMENT_FROM_WALLET, API_URL_GET_PAYMENT_FROM_PREPAID, API_URL_PERFORM_CREDIT_SUCCESS_ACTION } from './../../config/apiRouteConfig/index';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Injectable({
 providedIn: 'root'
})
export class PaymentService {

 constructor(private _http: HttpClient, private _app: AppService) { }
  newWindow:Window;
  
  razorpayDetails = {
    gatewayOrderId :'',
    transactionId: '',
    orderId : ''
  }

  private lastTxnStatus = new BehaviorSubject<any>('');
  lastTxnStatus$ = this.lastTxnStatus.asObservable();

  // settings for Razorpay
  options = {
    "key": environment.razorpayKey, // Enter the Key ID generated from the Dashboard
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    // "callback_url": '',

    "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      "email": "",
      "contact": "" //Provide the customer's phone number for better conversion rates 
  },

  "config": {
    display: {
      blocks: {
        banks: {
          name: 'Pay via Card',
          instruments: [
            {
              method: 'card'
            }
          ],
        },
      },
      sequence: ['block.banks'],
      preferences: {
        show_default_blocks: false,
      },
    },
  }
};


 getTransactionByOrderIdRiderId(orderId, riderId) {
  //  console.log("heyy");
   return this._http.get(API_URL_PAYMENT_ORDER + "/" + orderId + "/rider/" + riderId);
 }
 getAllPaymentTransactions(riderId, phone, start, end, seek, count) {
   let url = API_URL_ALL_PAYMENTS_TRANSACTIONS;
   let params = []
   if (riderId)
     params.push("riderId=" + riderId)
   if (phone)
     params.push("phone=" + phone)
   if (start)
     params.push("startTime=" + start)
   if (end)
     params.push("endTime=" + end)
   if (seek != null && count)
     params.push("seek=" + seek + "&count=" + count)
   if (params.length > 0)
     url += "?" + params.join("&")

   return this._http.get(url)
 }
 getRiderPayment() {
   return this._http.get(API_URL_ALL_RIDER_BALANCE)
 }
 getBluWalletTransactions(riderId, phone, start, end, seek, count) {
   let url = API_URL_ALL_BLUWALLET_TRANSACTIONS;
   let params = []
   if (riderId)
     params.push("riderId=" + riderId)
   if (phone)
     params.push("phone=" + phone)
   if (start)
     params.push("startTime=" + start)
   if (end)
     params.push("endTime=" + end)
   if (seek != null && count)
     params.push("seek=" + seek + "&count=" + count)

   if (params.length > 0)
     url += "?" + params.join("&")

   return this._http.get(url)
 }
 getAllWalletDetails() {
   return this._http.get('https://run.mocky.io/v3/946596e7-4d6e-4741-a42d-3b2629481031');
 }
 getRiderTransactions(riderId) {
   return this._http.get(API_URL_BLUWALLET_PANEL + "?" + "rider_Id=" + riderId)
 }

 addMoneyToWallet(amount){
   return this._http.post(API_URL_ADD_MONEY_TO_WALLET,{"amount":amount});
 }

 addMoneyToWalletFromTeller(rideReqId:any){
  return this._http.get(`${API_URL_ADD_MONEY_TO_WALLET_TELLER}/${rideReqId}/payment`)
 }

 checkStatus(orderId){
   return this._http.get(API_URL_CHECK_ADD_MONEY_STATUS+'/'+orderId+'/status');
 }

 getInvoiceDetails(start,end){
   let params = []
   let url = API_URL_FETCH_INVOICE_DATA;
   if (start)
     params.push("startTime=" + start)
   if (end)
     params.push("endTime=" + end)
     if (params.length > 0)
     url += "?" + params.join("&")

   return this._http.get(url)
 }
 getCreditHistory(businessId, start, end){
  //  console.log(API_URL_CREDIT_HISTORY+'?businessAccountId='+businessId+'&startTime='+start+'&endTime='+end)
   return this._http.get(API_URL_CREDIT_HISTORY+'?businessAccountId='+businessId+'&startTime='+start+'&endTime='+end);
 }
 addCreditTransactions(body){
   return this._http.post(API_URL_ADD_CREDIT,body);
 }
 getCurrentBalance(businessAccountId){

   return this._http.get(API_URL_CURRENT_BALANCE+'/'+businessAccountId);
 }
 getPrePaidTransactions(businessId, start, end){
   return this._http.get(API_URL_PREPAID_TRANSACTIONS+'?businessAccountId='+businessId+'&startTime='+start+'&endTime='+end);
 }

 getTaxName(){
  return this._app.getCountry() == "IN" ? 'GST' : 'Tax';
 }
//  store order details for credit card
  getrazorpayDetails(razorPayDetails){
  this.razorpayDetails = {
    gatewayOrderId : razorPayDetails.gatewayOrderId,
    transactionId: razorPayDetails.transactionId,
    orderId : razorPayDetails.orderId
  } }
 
 

async getCreditCardStatusForRazorPay(updateCreditCardStatusOfLaxtTxn){
   this.options['order_id'] = this.razorpayDetails.gatewayOrderId
   this.options['prefill']['contact'] = localStorage.getItem('phoneNo')
   this.options['prefill']['email'] = localStorage.getItem('email')
   this.options['handler'] = (res)=>this.razorpayHandler(res,updateCreditCardStatusOfLaxtTxn)

   this.newWindow = new window['Razorpay'](this.options).open();
 }

  razorpayHandler(res,updateCreditCardStatusOfLaxtTxn){
  let statusDto = {
    "razorpayOrderId": res.razorpay_order_id,
    "razorpaySignature":res.razorpay_signature,
    "razorpayPaymentId":res.razorpay_payment_id
  }
   //this._http.post(`${API_URL_RAZORPAY_PAYMENT_STATUS}${this.razorpayDetails.orderId}/transaction/${this.razorpayDetails.transactionId}/complete`, statusDto).subscribe(res=>{
    //if(res['statusCode'] >= 300){
     // Swal.fire({
       // icon: 'error',
       // title: 'Technical Error !',
        //text: res['message']
      //})
      // return 'Failure'
      //updateCreditCardStatusOfLaxtTxn("Failure");
    //}
    //else{
      // Swal.fire({
      //   title: '<span style="color:#5DCF5E">Payment Successful</span>',
      //   // icon: 'info',
      //   imageUrl:'../../../../../assets/addMoneySuccess.png',
      //   text: 'Ride Booked Successfully'
      // })
      // return 'Success'
      updateCreditCardStatusOfLaxtTxn("Success");
    //}
  //})
 }

 getCreditCardRideStatus(){
 return this._http.get(`${API_URL_PERFORM_CREDIT_SUCCESS_ACTION}${this.razorpayDetails.orderId}/status`)
 }

 fetchPendingPayment(){
  return this._http.post(API_URL_FETCH_PENDING_PAYMENTS, {})
 }


 getPaymentFromWallet(data){
  return this._http.post(API_URL_GET_PAYMENT_FROM_WALLET, data)
 }

 getPaymentFromPrePaid(data){
  return this._http.post(API_URL_GET_PAYMENT_FROM_PREPAID, data)
 }
}