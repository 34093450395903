import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_GET_COUNTRY_CODES } from 'src/app/config/apiRouteConfig';
import { CarVariantType, RideType } from '../types/container.type';
import { CommonSubscriptionService } from '../subscriptions/common/common-subscription.service';
import { Theme } from '../types/components.type';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryFeatureListService } from '../localization/country-feature-list/country-feature-list.service';
import { AppService } from 'src/app/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  allowedRideTypesDetails : RideType[] = [];
  allowedRideTypes : string[];
  
  callingCodeList = [];
  allCarVariants:CarVariantType[];
  selectedCountry: string;
  defaultAgmCoordinates:{lat: number, lng: number};
  leadTimes = {};


  superAdminList = ["346191","158699","1626","2526","1545058"];
  isAdminUser = false;

  constructor(private _http: HttpClient, 
              private _commonSubscription: CommonSubscriptionService,
              private _loader: NgxSpinnerService,
              private _countryFeature: CountryFeatureListService) { }


  setSelectedCountry(country){
    this.selectedCountry = country;
  }              

  getSelectedCountry(){
    return this.selectedCountry;
  }


  setLeadTimes(){
    this.leadTimes = this._countryFeature.getCountryFeatureList()['zoneIdBasedLeadTime'];
  }

  getLeadTimes(){
    return this.leadTimes;
  }

  setIsAdminUser(ssoId){
    this.isAdminUser = this.superAdminList.includes(ssoId.toString()) ? true : false;

  }

  getIsAdminUser(){
    return this.isAdminUser;
  }

  async setAllowedRideTypesDetails(){
    this._loader.show();
    this.allowedRideTypesDetails = [];
    let allRideTypes = await this._countryFeature.getCountryFeatureList()['defaultActiveRideTypes'];

    
    allRideTypes.forEach(rideTypeDetails=>{
      if(this.allowedRideTypes.includes(rideTypeDetails.code)){
        this.allowedRideTypesDetails.push(rideTypeDetails);
      }
      else{
        return;
      }
    });
    this._loader.hide();
  }

  getAllowedRideTypesDetails(){
    return this.allowedRideTypesDetails;
  }

  setAllowedRideTypes(rideTypes, isSharedRideEnabled = false){
    this.allowedRideTypes = rideTypes;
    isSharedRideEnabled ? this.allowedRideTypes.push('SHARED_RIDE') : null;
  }

  getAllowedRideTypes(){
    return this.allowedRideTypes;
  }

  setAllCarVariants(){
    this._loader.show();
    this.allCarVariants = this._countryFeature.getCountryFeatureList()['carVariants'];
    this._loader.hide();
  }

  getAllCarvariantDetails(){
    return this.allCarVariants;
  }

  setDefaultAgmCoordinates(){
    this._loader.show();
    this.defaultAgmCoordinates = this._countryFeature.getCountryFeatureList()['defaultCoordinates'];
    this._loader.hide();  
  }

  getDefaultAgmCoordinates(){
    return this.defaultAgmCoordinates;
  }

  getCarvariantDetails(selectedPackageCategories, priceMapIds){
    console.log(selectedPackageCategories, priceMapIds);    
    this.allCarVariants.forEach(variant=>{
            priceMapIds.forEach((mapId,index)=>{
              if(Object.keys(priceMapIds[index])[0] == variant.category){
                variant.priceMapId = mapId[variant.category];
              }
              else{
                return;
              }
            })
        });
    console.log(this.allCarVariants);
    let exportPackageDetails = this.allCarVariants.filter(val=>selectedPackageCategories.includes(val.category));
    return exportPackageDetails;
  }

  setCallingCodeList(){
    this._loader.show()
    this._http.get(API_URL_GET_COUNTRY_CODES).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        let tempCodeList = res['response']['countryCodePairList'].map(code=>{
            let tempLabel = code['countryNameInitial'] == '' ? 'Mostly Searched' : code['countryNameInitial'] ;
            let tempValue =  code['countryNameInitial'] == '' ? 'Mostly Searched' : code['countryNameInitial'] ;
            let tempItems = code['countries'].map(country=>{
              return {label: country.name, value: country.callingCode, flag: country.countryFlag, limit: country.phoneNumberLimit}
            });
          
            return {label: tempLabel, value: tempValue, items: tempItems}
        })

        this.callingCodeList = tempCodeList;
      }
      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'OK!'
        })
      }
    });
  }


  getCallingCodeList(){
    if(this.callingCodeList.length <= 0){
      this.setCallingCodeList();
      return this.callingCodeList; 
    }
    else{
      return this.callingCodeList;
    }
  }


    clearAllCarVariants(){
     this.getAllCarvariantDetails();
    }
  
}


