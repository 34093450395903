import { Injectable } from '@angular/core';
import { API_URL_SEND_OTP_RIDER, API_URL_BLOCK_RIDER,API_URL_TNC_INFO, API_URL_LIST_RIDER,  API_URL_TNC ,API_URL_ADD_RIDER_PROMO, API_URL_RIDER_DEVICE_INFORMATION, API_URL_RIDER_FEEDBACK, API_URL_PASTRIDE_CSV_DOWNLOAD, API_URL_REPORT_RIDER_FEEDBACK_CSV_DOWNLOAD,API_URL_RIDER_DETAILS,API_URL_ALL_PACKAGES,API_RIDER_BLU_WALLET_REFUND ,API_RIDE_DETAILS,API_RIDER_DETAILS,API_UPDATE_AGENT_DATA, API_URL_DUPLICATE_RECURRING_RIDE} from './../../config/apiRouteConfig/index';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RiderService {
  constructor(private _http: HttpClient) { }
  getRidersList() {
    return this._http.get(API_URL_LIST_RIDER);
  }
  blockThisRider(riderId, isBlocked) {
    return this._http.post(`${API_URL_BLOCK_RIDER}`, { riderId, blockUser: isBlocked });
  }
  setOtpToThisRider(riderId) {
    return this._http.get(`${API_URL_SEND_OTP_RIDER}/${riderId}`);
  }

  setRiderType(riderId: any, code) {
    return this._http.post(`${API_URL_ADD_RIDER_PROMO}`,{riderId, promoCode:code, source:"DISPATCH"});
  }
  getDeviceInformation(riderId) {
    return this._http.get(`${API_URL_RIDER_DEVICE_INFORMATION}/${riderId}`);
  }
  getRiderFeedback(startDate, endDate) {
    return this._http.get(`${API_URL_RIDER_FEEDBACK}` + '?startTime=' + startDate + '&endTime=' + endDate);
  }
  getmockRiderFeedback() {
    return this._http.get(`https://run.mocky.io/v3/cb30d721-f00d-415a-a2fb-09157d5bb6cb`);
  }
  downloadCSVRidersFeedback(startDate, endDate): Observable<Blob> {
    return this._http.get(API_URL_REPORT_RIDER_FEEDBACK_CSV_DOWNLOAD + '?startTime=' + startDate + '&endTime=' + endDate,
      { responseType: 'blob' });
  }

  initiateRiderBluWalletRefund(walletRefundData) {
    return this._http.post(`${API_RIDER_BLU_WALLET_REFUND}`,walletRefundData);
  }

  getRideDetails(riderId){
    return this._http.get(`${API_RIDE_DETAILS}`+'?riderId='+riderId);

  }
  getRidersData(filterType){
    return this._http.get(`${API_RIDER_DETAILS}`+"?filterType="+filterType);
  }
  updateAgentData(body){
    return this._http.put(`${API_UPDATE_AGENT_DATA}`,body);
  }

  getRiderData(phoneNo, callingCode): Observable<any> {
    return this._http.get(API_URL_RIDER_DETAILS + "?phone=" + phoneNo + "&callingCode=%2B" + callingCode.toString());
  }
  getAllRentalPackages(): Observable<any> {
    return this._http.get(API_URL_ALL_PACKAGES);
  }
  getRiderDataByRiderId(riderId): Observable<any> {
    return this._http.get(API_URL_RIDER_DETAILS + "/" + riderId);
  }
  getTnC(){
    return this._http.get(API_URL_TNC);
  }

  getTnCInfo(){
    return this._http.get(API_URL_TNC_INFO);
  }


}
