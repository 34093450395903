<div class="main-container" >

    <div class="phone-container">
        <custom-input [inputType]="'PHONE'" [inputBoxDetails]="customerDetails.phoneNumber" (onSendInputData)="getInfoFromInputThroughApi($event)"></custom-input>
    </div>
    
    <div class="name-container">
        <div class="first-name">
            <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.firstName"></custom-input>
        </div>
        <div class="last-name">
            <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.lastName"></custom-input>
        </div>
    </div>

    <div class="email-container">
        <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.email"></custom-input>
    </div>

    <div class="submit-container">
        <custom-button [buttonDetails]="nextCta" (onCustomButtonClick)="submitCustomerDetails()"></custom-button>
    </div>
    
</div>