import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IsAirportRide, RideType } from '../../types/container.type';
import { MapDto, PopupType } from '../../types/components.type';
import { JourneyDetailsContainerService } from '../../getter-setter/container/create-ride/journey-details-container.service';

@Injectable({
  providedIn: 'root'
})
export class CreateRideSubscriptionService {

  constructor() { }


  createRideActiveStep = new Subject<string>(); // 
  mapCoordinates = new Subject<MapDto>();
  
  
  
  triggerLocationCta = new Subject<boolean>();
  triggerCarVariantCta = new Subject<boolean>();
  triggerTimestampCta = new Subject<boolean>();
  
  addLocationValidation = new Subject<void>();
  addLocationValidationStatus = new Subject<boolean>();
  
  additionalDetailsValidation = new Subject<void>();
  additionalDetailsValidationStatus = new Subject<boolean>();

  fetchPackage = new Subject<boolean>();
  

  isSlotRequired = new Subject<boolean>();
  isAirportRide = new Subject<boolean>();

  editRideData = new Subject<string>();
  editJourneyDetails = new Subject<string>();

  // Create Ride Steps Subscription
  emitCreateRideActiveStepObserver(nextStep){
    this.createRideActiveStep.next(nextStep);
  }

  fetchCreateRideActiveStepObserver(){
   return this.createRideActiveStep.asObservable();
  }


// Location Cta Subscription
  emitTriggerLocationCtaObserver(moveNext){
    this.triggerLocationCta.next(moveNext);
  }

  fetchTriggerLocationCtaObserver(){
    return this.triggerLocationCta.asObservable();
  }


// Car Variant Cta Subscription
  emitTriggerCarVariantCtaObserver(moveNext){
    this.triggerCarVariantCta.next(moveNext);
  }

  fetchTriggerCarVariantCtaObserver(){
    return this.triggerCarVariantCta.asObservable();
  }


 // Timestamp cta Subscription
  emitTriggerTimestampCtaObserver(moveNext){
    this.triggerTimestampCta.next(moveNext);
  }

  fetchTriggerTimestampCtaObserver(){
  return this.triggerTimestampCta.asObservable();
  }


  // Is Airport Ride Subscription
  emitIsAirportRideObserver(isAirportRide){
    let flatArr = Object.values(isAirportRide).flat();
    this.isAirportRide.next(flatArr.includes(true) ? true : false);
  }

  fetchIsAirportRideObserver(){
  return this.isAirportRide.asObservable();
  }


  // Google maps subscription
  emitMapCoordinatesObserver(locationData){  
      this.mapCoordinates.next(locationData);
    }

  fetchMapCoordinatesObserver(){  
    return this.mapCoordinates.asObservable();
    }


  // Package Subscription
  emitPackageObserver(isActive){
    this.fetchPackage.next(isActive)
  }

  fetchPackageObserver(){
    return this.fetchPackage.asObservable();
  }

  // Location Validation Subscription
  emitAddLocationValidationObserver(){
    this.addLocationValidation.next();
  }

  fetchAddLocationValidationObserver(){
  return this.addLocationValidation.asObservable();
  }


  // Location Validation Status Subscription
  emitAddLocationValidationStatusObserver(status){
    this.addLocationValidationStatus.next(status);
  }

  fetchAddLocationValidationStatusObserver(){
    return this.addLocationValidationStatus.asObservable();
  }


  // BRR , PickupNotes and Expense Code Validation Subscription
  emitAdditionalDetailsValidationObserver(){
    this.additionalDetailsValidation.next();
  }

  fetchAdditionalDetailsValidationObserver(){
  return  this.additionalDetailsValidation.asObservable();
  }


  // BRR , PickupNotes and Expense Code Validation Status Subscription
  emitAddtionalDetailsValidationStatusObserver(status){
    this.additionalDetailsValidationStatus.next(status);
  }

  fetchAdditionalDetailsValidationStatusObserver(){
    return this.additionalDetailsValidationStatus.asObservable();
  }


  // slots subscription
  emitIsSlotRequired(slotRequired){
    this.isSlotRequired.next(slotRequired);
  }

  fetchIsSlotRequired(){
    return this.isSlotRequired.asObservable();
  }

  //edit ride data through payemnt section
  emitEditRideDataSubscription(dataType){
    this.editRideData.next(dataType);
  }

  fetchEditRideDataSubscription(){
    return this.editRideData.asObservable();
  }

  //edit journey details data 
  emitEditJourneyDetails(type){
    this.editJourneyDetails.next(type);
  }

  fetchEditJourneyDetails(){
    console.log('subscription is working when editing')
   return this.editJourneyDetails.asObservable();
  }

 
  }
  
