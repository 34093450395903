<div class="main-container">
    <span class="additional-details-title">Additional Details</span>
    
    <!-- Pickup Notes -->
    <div class="additional-details-container">
        <div class="pickup-notes-container">
            <div class="image-container">
                <img src="assets/pickupNotes.svg" alt="">
            </div>
    
            <div class="input-container">
                <custom-text-area [textAreaDetails]="pickupNotesDetails"></custom-text-area><br>    
            </div>
        </div>
        
        
        <!-- Business Ride Reason -->
        <div class="business-ride-reason-container">
            <div class="image-container" *ngIf="businessRideReasonsFlags.enabled">
                <img src="assets/brrAndExpense.svg" alt="">
            </div>
    
            <div class="input-container">
        <dropdown [dropdownDetails]="businessRideReasonDropDownDetails" *ngIf="businessRideReasonsFlags.dropdown" (changehandler)="onDropdownValueChange('REASON')"></dropdown>
        <custom-input inputType="BASIC" [inputBoxDetails]="businessRideReasonInputDetails" *ngIf="businessRideReasonsFlags.textBox"></custom-input><br>
            </div>
        </div>


        <!-- Expense Code -->
        <div class="expense-code-container" *ngIf="expenseCodeFlags.enabled">

            <div class="image-container" *ngIf="expenseCodeFlags.enabled">
                <img src="assets/brrAndExpense.svg" alt="">
            </div>
    
            <div class="input-container">
        <dropdown [dropdownDetails]="expenseCodeDropDownDetails" *ngIf="expenseCodeFlags.dropdown" (changehandler)="onDropdownValueChange('CODE')"></dropdown>
        <custom-input inputType="BASIC" [inputBoxDetails]="expenseCodeInputDetails" *ngIf="expenseCodeFlags.textBox"></custom-input>
        </div>
        </div>
    </div>

</div>