import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { Theme } from 'src/app/app-v2/types/components.type';

import { LocationInputDetails } from 'src/app/app-v2/types/container.type';
import { journeyDetails } from 'src/app/app-v2/validations/createRide/create-ride-validation';


@Component({
  selector: 'add-location-container',
  templateUrl: './add-location-container.component.html',
  styleUrls: ['./add-location-container.component.scss']
})
export class AddLocationContainerComponent implements OnInit,OnDestroy {
  addLocationValidationSubscription: Subscription;

  locationInputs: LocationInputDetails = {
    PICK: {
      placeholder: 'Enter Location..',
      type: 'text',
      inputValue: {
        lat: 0,
        lng: 0,
        place: null
      },
      label: 'Pickup Location',
      name: 'PICKUP',
      zoneId: 0,
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: false,
      options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
      isAirportEnabled: true,
      isAirportLocation: false,
      landmarks: [],
      isLandmarkEnabled: true,
      isLandmarkSelected: [],
      showLandmark: false
    },

    DROP:
    {
      placeholder: 'Enter Location..',
      type: 'text',
      inputValue: {
        lat: 0,
        lng: 0,
        place: null
      },
      label: 'Drop Location',
      name: 'DROP',
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: false,
      options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
      isRequired: true,
      isAirportEnabled: true,
      isAirportLocation: false,
      landmarks: [],
      zoneId: 0,
      isLandmarkEnabled: true,
      isLandmarkSelected: [],
      showLandmark: false
    },

    STOPS: []
  }



  constructor(private _rideType: SelectRideTypeContainerService,
    private _common: CommonService,
    private _subscription: CreateRideSubscriptionService,
    private _journey: JourneyDetailsContainerService,)
    { }
  

  ngOnInit() {
    this.locationInputs.DROP.isRequired = this._rideType.getSelectedRideTypeDetails().isDropRequired;
    this.locationInputs.DROP.validation = !this._rideType.getSelectedRideTypeDetails().isDropRequired ? null : {
      isError: false,
      isSuccess: false,
      message: ''
    }

    this.fetchPrevData();
    this.startAddLocationValidationSubscription();
  }


  addStop(lastIndex) {
    this.locationInputs.STOPS.push({
      placeholder: 'Enter Location..',
      type: 'text',
      inputValue: {
        lat: 0,
        lng: 0,
        place: null,
        position: lastIndex + 1
      },
      label: `Stop ${lastIndex + 1}`,
      name: 'STOP',
      zoneId: 0,
      validation: null,
      isDisabled: false,
      options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
      isAirportEnabled: true,
      isAirportLocation: false,
      landmarks: [],
      isLandmarkEnabled: true,
      isLandmarkSelected: [],
      showLandmark: false
    })
  }

  deleteStop(index) {
    if (this.locationInputs.STOPS.length > 0) {
      this.locationInputs.STOPS.splice(index, 1);
      this._journey.setStops(this.locationInputs.STOPS);
      this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.STOPS, type: 'STOPS' });
      this.renderStops();
    }
  }


  renderStops() {
    this.locationInputs.STOPS.forEach((stop, index) => {
      stop.label = `Stop ${index + 1}`;
    })
  }



  async getLocationDataFromInput(event, type) {
    
    switch (type) {
      case 'PICKUP':
        this._journey.setPickupLocation(this.locationInputs.PICK.inputValue);
        this.setExtraData('PICK');
        this.checkIfAirportLocation();
        this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.PICK.inputValue, type: type});
        return

      case 'DROP':
        this._journey.setDropLocation(this.locationInputs.DROP.inputValue);
        this.setExtraData('DROP');
        this.checkIfAirportLocation();
        this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.DROP.inputValue, type: type });
        
        return

      case 'STOPS':
        let stopsVal = await this.locationInputs.STOPS.map(stop => stop.inputValue);
        this._journey.setStops(stopsVal);
        this.checkIfAirportLocation();
        this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.STOPS, type: type });
        return
    }

  }


  setExtraData(type){
    let extraData = {
      isAirportLocation: this.locationInputs[type].isAirportLocation,
      landmarks: this.locationInputs[type].landmarks,
      zoneId: this.locationInputs[type].zoneId,
      selectedLandmarkIndex: this.fetchSelectedLandmarkIndex(this.locationInputs[type].landmarks, 'PICK')
    }

    switch(type){
      case 'PICK':
        this._journey.setPickupLocationExtraFields(extraData);
        return;
      case 'DROP':
        this._journey.setDropLocationExtraFields(extraData);
        return;
    }
    
  }

  checkIfAirportLocation() {
    let isAirportLocationDto = {
      PICKUP: this.locationInputs.PICK.isAirportLocation,
      DROP: this.locationInputs.DROP.isAirportLocation,
      STOPS: this.locationInputs.STOPS.map(stop => stop.isAirportLocation)
    }

    this._subscription.emitIsAirportRideObserver(isAirportLocationDto);
  }

  fetchSelectedLandmarkIndex(landmarks, type) {
    let selectedLandmarkStatus = landmarks.map(landmark => this.locationInputs[type].inputValue.place === landmark.place ? true : false);
    let index = selectedLandmarkStatus.includes(true) ? selectedLandmarkStatus.indexOf(true) : null;
    return index;
  }


  fetchPrevData() {

    this.locationInputs.PICK.inputValue = this._journey.getPickupLocation();
    this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.PICK.inputValue, type: 'PICKUP' });

    this.locationInputs.DROP.inputValue = this._journey.getDropLocation();
    this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.DROP.inputValue, type: 'DROP' });

    this._journey.getStops().forEach((stop, index) => {
      this.addStop(index);
      this.locationInputs.STOPS[index].inputValue = stop;
    })
    this._subscription.emitMapCoordinatesObserver({ coordinates: this.locationInputs.STOPS, type: 'STOPS' });
    this.fetchExtraFields();
  }

  fetchExtraFields() {
    let pickupExtraData = this._journey.getPickupLocationExtraFields();
    let dropExtraData = this._journey.getDropLocationExtraFields();


    this.locationInputs.PICK.isAirportLocation = pickupExtraData.isAirportLocation;
    this.locationInputs.PICK.landmarks = pickupExtraData.landmarks;
    this.locationInputs.PICK.landmarks.forEach(landmark => this.locationInputs.PICK.isLandmarkSelected.push(false));
    this.locationInputs.PICK.showLandmark = pickupExtraData.landmarks.length > 0 ? true : false;
    pickupExtraData.selectedLandmarkIndex != null ? this.locationInputs.PICK.isLandmarkSelected[pickupExtraData.selectedLandmarkIndex] = true : null;


    this.locationInputs.DROP.isAirportLocation = dropExtraData.isAirportLocation;
    this.locationInputs.DROP.landmarks = dropExtraData.landmarks;
    this.locationInputs.DROP.landmarks.forEach(landmark => this.locationInputs.DROP.isLandmarkSelected.push(false));
    this.locationInputs.DROP.showLandmark = dropExtraData.landmarks.length > 0 ? true : false;
    pickupExtraData.selectedLandmarkIndex != null ? this.locationInputs.DROP.isLandmarkSelected[dropExtraData.selectedLandmarkIndex] = true : null;


    this.checkIfAirportLocation();
  }

  startAddLocationValidationSubscription() {
    this.addLocationValidationSubscription = this._subscription.fetchAddLocationValidationObserver().subscribe(async res => {
      console.log('validating');
      let promises = await Object.keys(this.locationInputs).map(async inpType=>{
        if(inpType == 'STOPS'){
          return false;
          }
        else{
            if(this.locationInputs[inpType]['validation'] != null){
              let res = await this._journey.checkValidation(inpType);
              this.locationInputs[inpType]['validation'] = res;
              return this.locationInputs[inpType]['validation']['isError'];
            }
            else{
              return false;
            }}
        });
      
      await Promise.all(promises);

      let validatorStatus = Object.keys(this.locationInputs).map(inpType=>{
        if(inpType == 'STOPS'){
          return false;
        }
        else{
          if(!this.locationInputs[inpType]['validation']){
            return false;   
          }
          else{
            return this.locationInputs[inpType]['validation']['isError'];
          }}
      });

      console.log(validatorStatus);
      this._subscription.emitAddLocationValidationStatusObserver(!validatorStatus.includes(true) ? true : false);
    });
  }



ngOnDestroy(): void {
  this.addLocationValidationSubscription.unsubscribe();
}
}

