import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { CreateRideService } from 'src/app/app-v2/getter-setter/container/create-ride/create-ride-container.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CustomButton, DropdownDetails, Theme } from 'src/app/app-v2/types/components.type';

@Component({
  selector: 'car-variant-container',
  templateUrl: './car-variant-container.component.html',
  styleUrls: ['./car-variant-container.component.scss']
})
export class CarVariantContainerComponent implements OnInit, OnDestroy{
fetchPackageSubscription: Subscription;

carVariantCta: CustomButton = {
  label: 'Next >',
  left: false,
  right: false,
  leftUrl: "",
  rightUrl: "",
  disabled: false
}

packageDropDownDetails : DropdownDetails ={
  selectedValue: {
    label: '',
    value: ''
  },
  options: [],
  validation: null,
  label: 'Select Package',
  name: 'package',
  placeholder : 'Select Package',
  isDisabled: false
}

carVariantDetails = [];
packageData = {};
isPackageRequired = false;
isAirportRide = false;

constructor(private _journeyApi: JourneyDetailsApiService, 
            private _journey: JourneyDetailsContainerService,
            private _createRide: CreateRideService,
            private _subscription: CreateRideSubscriptionService,
            private _common: CommonService,
            private _commonSubscription: CommonSubscriptionService,
            private _rideType: SelectRideTypeContainerService,
            private _loader: NgxSpinnerService){}

ngOnInit(): void {
  this.isPackageRequired = this._rideType.getIsPackageRequired();
  this.isAirportRide = this._rideType.getIsAirportRide();

  this.isPackageRequired ? this.startPackageSubscription() : null;
  this.isAirportRide ? this.startAirportSubscription() : null;
}


selectVariant(index){
    this.carVariantDetails.forEach(variant=>{
      variant.isActive = false;
    });

    this.carVariantDetails[index].isActive = true;
    this.setDataAccordingToRideType(index);
}


setDataAccordingToRideType(index){
  let selectedRideType = this._rideType.getSelectedRideTypeDetails().code;

  switch(selectedRideType){
    case 'SCHEDULED_RIDE':
      this._journey.setSubCategory(this._rideType.getIsAirportRide() ?  `${this.carVariantDetails[index].category}_AIRPORT` : null);
      return;
    
    case 'SCHEDULED_RENTALS':
      console.log(this.carVariantDetails[index].priceMapId);
      this._journey.setPriceMapId(this.carVariantDetails[index].priceMapId);
      this._rideType.setIsSlotRequired(this.carVariantDetails[index].slotsRequired);
      return;
  }


}

startPackageSubscription(){
  this.fetchPackageSubscription = this._subscription.fetchPackageObserver().subscribe(res=>{
    if(res && this._rideType.getSelectedRideTypeDetails().packageRequired){
      this._loader.show();

      this._journeyApi.fetchPackages(this._journey.getPickupLocation()).subscribe(res=>{
        this._loader.hide();
        if(res['statusCode'] == 200){
          this.packageData = res['response'];
          this.populatePackageInDropdown(res['response']);
        }
        else{
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: 'Technical Error!',
            message: res['message'],
            ctaLabel: 'OK!'
          })
        }
      });
    }
    else{
      return
    }
  })
}


startAirportSubscription(){
  if(this._rideType.getIsAirportRide()){
    this.carVariantDetails = this._common.getAllCarvariantDetails();
  }
  else{
    return ;
  }
}

populatePackageInDropdown(packageList){
  let packageCodes = Object.keys(packageList);
  Object.values(packageList).forEach((packageData,index)=>{
      let km = (packageData['baseDistance'] / 1000);
      let time = (packageData['baseTime'] / 3600000)
      let label = km + ' Km / ' + time + ' hr';
      let val = packageCodes[index]
      
    this.packageDropDownDetails.options.push({ "label": label, "value": val})
  })
}


onPackageSelect(){
  this._journey.setPriceMapId(null);
  this.clearCarVariantData();
  this._journey.setPackageCode(this.packageDropDownDetails.selectedValue.value);
  this.carVariantDetails = [];
  let priceMapIds = {}
  
  let selectedPackageCategories = this.packageData[this.packageDropDownDetails.selectedValue.value]['categories'];
  let packageTypes = selectedPackageCategories.map(category=>category.categoryCode)
      priceMapIds = selectedPackageCategories.map(categoryData=>{
        return {[categoryData.categoryCode]: categoryData.id};
      })
      
  this.carVariantDetails = this._common.getCarvariantDetails(packageTypes, priceMapIds);
 }


 submitCarVariantDetails(){
  console.log(this._rideType.getSelectedRideTypeDetails().code, this._journey.getPriceMapId())

  if(this._rideType.getSelectedRideTypeDetails().code == 'SCHEDULED_RIDE'){
    if(this._journey.getSubCategory() == null){
      this._commonSubscription.emitAlert({
        theme: Theme.WARNING,
        title: 'Select Car Variant',
        message: 'Please select car variant to continue',
        ctaLabel: 'Ok'
      });
      return;
    }

    else{
      this._subscription.emitTriggerCarVariantCtaObserver(true);
    }
  }

  else if(this._rideType.getSelectedRideTypeDetails().code == 'SCHEDULED_RENTALS'){
    if(this._journey.getPriceMapId() == null){
      this._commonSubscription.emitAlert({
        theme: Theme.WARNING,
        title: 'Select Car Variant',
        message: 'Please select car variant to continue',
        ctaLabel: 'Ok'
      });
      return;
    }
    else{
      this._subscription.emitTriggerCarVariantCtaObserver(true);
    }
  }
}


 clearCarVariantData(){
  this.carVariantDetails.forEach(variant=>{
    variant.isActive = false;
  });
 }

 ngOnDestroy(): void {
  this.clearCarVariantData();
 }

}
