import { AccordianDetails, CustomDropDownInputType, CustomInputType, PlacesInputType } from "./components.type"

export type RideType = {
    name: string,
    isActive: boolean,
    isRecurring:boolean,
    code: string,
    url: string,
    packageRequired: boolean,
    isDropRequired: boolean,
    isAirportRide: boolean
    slotsRequired: boolean
}

export type RideTypeExportDto ={
    nextScreenCode : string,
    selectedRideType: string,
}


export const CreateRideSteps = {
    CUSTOMER_DETAILS: 'Customer Details',
    JOURNEY_DETAILS: 'Journey Details',
    WEEK_SCHEDULE: 'Week Schedule',
    PAYMENT_DETAILS: 'Payment Details'
}

export type CustomerDetails = {
    phoneNumber : CustomDropDownInputType,
    firstName: CustomInputType,
    lastName: CustomInputType,
    email: CustomInputType

}

export type CustomerDetailsApiResponse = {
        riderId: number,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        ssoId: number,
        callingCode: string
}

export type JourneyDetails = {
    locationDetails : AccordianDetails,
    carVariantDetails : AccordianDetails,
    timestampDetails : AccordianDetails
}


export const JourneyDetailsStep = {
    LOCATION_DETAILS: 'locationDetails',
    CAR_DETAILS: 'carVariantDetails',
    TIMESTAMP: 'timestampDetails',
}

export type JourneyDetailsPayloadDto = {
    pickupLat: number | null,
    pickupLong: number | null,
    pickupLocation: string | null,
    dropLat: number | null,
    dropLong:number | null,
    dropLocation: string | null,
    stops: Location[],
    map: string | null,
    platform: string | null,
    startTime: number | null,
    priceMapId: number | null,
    otherFlags: JourneyDetailsOtherFlags,
    packageCode?: string | null
    pickupNotes: PickupNotesDto | null
    subCategory: string | null
}


export type PickupNotesDto = {
    createdTimestamp: number;
    isForAll: boolean;
    lastUpdatedTimestamp: number;
    latitude: number;
    longitude: number;
    notes: string; 
}


export type JourneyDetailsOtherFlags={
    businessRideReason: string,
    expenseCode: string
}

export type Location = {
    lat: number,
    lng: number,
    place: string | null,
    position? : number,
    stop? : string
}


export type LocationInputDetails = {
    PICK : PlacesInputType,
    DROP : PlacesInputType,
    STOPS : PlacesInputType[]

}

export type ReasonsDto = {
    enabled: boolean,
    itemList: string[],
    mandatory: boolean,
    otherRequired: boolean,
    type : string | null
}

export type ReasonsListApiResponse = {
    businessRideReasonDetails: ReasonsDto | null
    expenseCodeDetails:  ReasonsDto | null
}

export type IsAirportRide = {
    PICKUP : boolean,
    DROP: boolean,
    STOPS: boolean[]
}

export type CarVariantType = {
    carUrl : string,
    carLabel: string, 
    category: string, 
    extraPoints: {url: string, label: string}[], 
    priceMapId : number,
    slotsRequired: boolean,
    country: string, 
    isActive: boolean
    extraInfo?: string[]
}


