import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { JourneyDetailsPayloadDto, Location } from 'src/app/app-v2/types/container.type';
import { journeyDetails } from 'src/app/app-v2/validations/createRide/create-ride-validation';
import { CreateRideValidationService } from 'src/app/app-v2/validations/createRide/create-ride-validation.service';
import { SelectRideTypeContainerService } from './select-ride-type-container.service';

@Injectable({
  providedIn: 'root'
})
export class JourneyDetailsContainerService {

  journeyDetails: JourneyDetailsPayloadDto = {
    pickupLat: null,
    pickupLong: null,
    pickupLocation: null,
    dropLat: null,
    dropLong:null,
    dropLocation: null,
    stops: [],
    map: "",
    platform: 'WEB',
    startTime: null,
    priceMapId: null,
    otherFlags: {
      businessRideReason : '',
      expenseCode: ''
    },
    packageCode: null,
    subCategory: null,
    pickupNotes: null
  }



  addLocationExtraFields = {
    PICK : {
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    DROP:{
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    STOPS : []
  }  

  addLocationValidation = true;
  selectedZoneIdForSlots = 0;

  journeyAccDetails = {
    locationDetails : {  
      label : "Enter pickup/drop locations, pickup notes and business ride reason", 
      isActive: false,
      isComplete: false,
      isDisabled: true,
      isVisible: true
     },

carVariantDetails : { 
       label : "Select car variant",
       isActive: false, 
       isComplete: false, 
       isDisabled: true, 
       isVisible: false,
     },

timestampDetails : { 
     label : "Select date and time" ,
     isActive:  false,
     isComplete: false,
     isDisabled: true,
     isVisible: true,
     }
}


  constructor(private _createRideValidation : CreateRideValidationService) {}
  
  getLocationAccDetails(){
    this.journeyAccDetails.locationDetails.isComplete = false;
    return this.journeyAccDetails.locationDetails;
  }

  getCarvariantAccDetails(){
    this.journeyAccDetails.carVariantDetails.isComplete = false;
    return this.journeyAccDetails.carVariantDetails;
  }

  getTimestampAccDetails(){
    return this.journeyAccDetails.timestampDetails;
  }


  setPickupLocation(coordinates){
    this.journeyDetails.pickupLat = coordinates.lat;
    this.journeyDetails.pickupLong = coordinates.lng;
    this.journeyDetails.pickupLocation = coordinates.place;
  }

  getPickupLocation(){
    let pickUpDto = {
      lat: this.journeyDetails.pickupLat,
      lng: this.journeyDetails.pickupLong,
      place: this.journeyDetails.pickupLocation
    }
    return pickUpDto;
  }

  setDropLocation(coordinates){
    this.journeyDetails.dropLat = coordinates.lat;
    this.journeyDetails.dropLong = coordinates.lng;
    this.journeyDetails.dropLocation = coordinates.place;
  }

  getDropLocation(){
    let dropDto = {
      lat: this.journeyDetails.dropLat,
      lng: this.journeyDetails.dropLong,
      place: this.journeyDetails.dropLocation
    }

    return dropDto;
  }

  setSelectedZoneIdForSlots(zoneId){
    this.selectedZoneIdForSlots = zoneId;
  }

  getSelectedZoneIdForSlots(){
    return this.selectedZoneIdForSlots;
  }

  setStops(stops){
    this.journeyDetails.stops = stops; 
  }

  getStops(){
    if(this.journeyDetails.dropLocation != null){
      this.journeyDetails.stops.pop();
    }
    return this.journeyDetails.stops;
  }

  setPackageCode(code){
    this.journeyDetails.packageCode = code;
  }

  getPackageCode(){
    return this.journeyDetails.packageCode;
  }

  setPriceMapId(id){
    this.journeyDetails.priceMapId = id;
  }

  getPriceMapId(){
    return this.journeyDetails.priceMapId;
  }

  setSubCategory(category){
    this.journeyDetails.subCategory = category;
  }

  getSubCategory(){
    return this.journeyDetails.subCategory;
  }

  setPickupLocationExtraFields(extraData){
    this.addLocationExtraFields.PICK.isAirportLocation = extraData.isAirportLocation;
    this.addLocationExtraFields.PICK.landmarks = extraData.landmarks;
    this.addLocationExtraFields.PICK.zoneId = extraData.zoneId;
    this.addLocationExtraFields.PICK.selectedLandmarkIndex = extraData.selectedLandmarkIndex;
  }

  setDropLocationExtraFields(extraData){
    this.addLocationExtraFields.DROP.isAirportLocation = extraData.isAirportLocation;
    this.addLocationExtraFields.DROP.landmarks = extraData.landmarks;
    this.addLocationExtraFields.DROP.zoneId = extraData.zoneId;
    this.addLocationExtraFields.DROP.selectedLandmarkIndex = extraData.selectedLandmarkIndex;
  }

  getPickupLocationExtraFields(){
    return this.addLocationExtraFields.PICK;
  }

  getDropLocationExtraFields(){
    return this.addLocationExtraFields.DROP;
  }


  setPackageContainerDetails(packageDetails){
    this.journeyDetails.priceMapId = packageDetails.priceMapId;
    this.journeyDetails.packageCode=packageDetails.packageCode;
  }

  getPackageContainerDetails(){
    let packageContainerDetailsDto={
      priceMapid : this.journeyDetails.priceMapId,
      packageCode: this.journeyDetails.packageCode,
    }
    return packageContainerDetailsDto;
  }

  setTimestampDetails(timestamp){
    this.journeyDetails.startTime = timestamp;
  }

  getTimestampDetails(){
    return this.journeyDetails.startTime;
  }

  getJourneyDetailsToRequestRide(){
    return this.journeyDetails;
  }

  setAddtionalDetails(otherflags, pickupNotes){

    let pickupNotesDto ={
      createdTimestamp: new Date().getTime(),
      isForAll: false,
      lastUpdatedTimestamp: new Date().getTime(),
      latitude: this.journeyDetails.pickupLat,
      longitude: this.journeyDetails.pickupLong,
      notes: pickupNotes
    }
    this.journeyDetails.otherFlags = otherflags,
    this.journeyDetails.pickupNotes = pickupNotes == '' ? null : pickupNotesDto;
  }


  async checkValidation(field: string, index: number = 0){
    switch(field){
      case 'PICK':
        var x = await this._createRideValidation.validatePickupLocation(this.journeyDetails.pickupLocation);
        return x;
        
      case 'STOPS':
        
        var x = await this._createRideValidation.validateStopLocation(this.journeyDetails.stops[index]);
        return x;  
    
      case 'DROP':
        var x = await this._createRideValidation.validateDropLocation(this.journeyDetails.dropLocation);
        return x;  

      case 'businessRideReason':
        var x = await this._createRideValidation.validateBusinessRideReason(this.journeyDetails.otherFlags.businessRideReason);
        return x;   
        
      case 'expenseCode':
        var x = await this._createRideValidation.validateExpenseCode(this.journeyDetails.otherFlags.expenseCode);
        return x;   
    }
  }


  setAddLocationValidation(validation){
    this.addLocationValidation = validation;
  }

  getAddLocationValidation(){
    return this.addLocationValidation;
  }

  
  getPickupLocationZoneId(){
    return this.addLocationExtraFields.PICK.zoneId;
  }


  clearJourneyDetails(){
    this.journeyDetails={
    pickupLat: null,
    pickupLong: null,
    pickupLocation: null,
    dropLat: null,
    dropLong:null,
    dropLocation: null,
    stops: [],
    map: "",
    platform: 'WEB',
    startTime: null,
    priceMapId: null,
    otherFlags: {
      businessRideReason : '',
      expenseCode: ''
    },
    packageCode: null,
    subCategory: null,
    pickupNotes: null
  }

  this.clearAddLocationExtraFields();
}


clearAddLocationExtraFields(){
  this.addLocationExtraFields = {
    PICK : {
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    DROP:{
      isAirportLocation : false,
      landmarks : [],
      selectedLandmarkIndex : 0,
      zoneId: 0
    },
    STOPS : []
  }  
}

}
