import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { CreateRideService } from 'src/app/app-v2/getter-setter/container/create-ride/create-ride-container.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CalendarType, CustomButton, CustomDropDownType, CustomInputType, Theme } from 'src/app/app-v2/types/components.type';
import { CreateRideSteps, JourneyDetailsStep } from 'src/app/app-v2/types/container.type';

@Component({
  selector: 'timestamp-container',
  templateUrl: './timestamp-container.component.html',
  styleUrls: ['./timestamp-container.component.scss']
})
export class TimestampContainerComponent implements OnInit{
  isSlotRequiredSubscription: Subscription;
  allSlotsData = {};
  zoneBasedLeadTime = this._common.getLeadTimes();

  timeStampContainerCta: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: "",
    rightUrl: "",
    disabled: false
  }

  timestampInputDetails: CalendarType = {
    placeholder : '(DD/MM/YYYY) (HH/MM)',
    inputValue: null,
    label: "Select Date and Time",
    name: 'dateTime',
    validation:  null,
    isDisabled: false,
    format: 'dd/mm/yy',
    selection: 'single',
    showTime : !this._rideType.getDefaultSelectedRideTypeDetails().slotsRequired,
    minDate: new Date(),
    stepMinute: this._common.getIsAdminUser() ? 1 : 15,
    stepHour: 1,
    maxDate: this.setMaxDate()
  }

  hourDropDownDetails: CustomDropDownType ={
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'HH',
    name: 'hourSlots',
    placeholder : 'Select Hour',
    isDisabled: true
  }


  minDropDownDetails: CustomDropDownType ={
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'MM',
    name: 'minSlots',
    placeholder : 'Select Minutes',
    isDisabled: true
  }

  slots;
  isSlotsRequired = this._rideType.getDefaultSelectedRideTypeDetails().slotsRequired;

  constructor(private _journey: JourneyDetailsContainerService,
              private _createRide: CreateRideService,
              private _journeyApi: JourneyDetailsApiService,
              private _subscription: CreateRideSubscriptionService,
              private _loader: NgxSpinnerService,
              private _rideType: SelectRideTypeContainerService,
              private _common: CommonService,
              private _commonSubscription: CommonSubscriptionService
  ){}

  ngOnInit(): void {
    this.startIsSlotRequiredSubscription();
  }

  panelVisible(){
    this.timestampInputDetails.inputValue = this.timestampInputDetails.inputValue == null ? this.handleDatePicker() : this.timestampInputDetails.inputValue; 
  }

  timeStampCtaClick(){
    if(this.isSlotsRequired){
      let selectedValue = new Date(this.timestampInputDetails.inputValue);
      
      let selectedDay = new Date(selectedValue).getDate();
      let selectedMonth = new Date(selectedValue).getMonth() + 1;
      let selectedYear = new Date(selectedValue).getFullYear();

      let selectedHours = this.hourDropDownDetails.selectedValue.value;
      let selectedMinutes = this.minDropDownDetails.selectedValue.value;

      let selectedDate = new Date(`${selectedMonth}/${selectedDay}/${selectedYear},${selectedHours}:${selectedMinutes}`);

      this._journey.setTimestampDetails(selectedDate.getTime());

      if((this.timestampInputDetails.inputValue == null) && this.hourDropDownDetails.selectedValue.value == '' && this.minDropDownDetails.selectedValue.value == ''){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Date !',
          message: 'Please Select Date and slots to continue',
          ctaLabel: 'OK'
        });
        return ;
      }
      else{
        this._subscription.emitTriggerTimestampCtaObserver(true);
        return;
      }
      
    }

    else{
      if(this.timestampInputDetails.inputValue == null){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Date !',
          message: 'Please Select Date and time to continue',
          ctaLabel: 'OK'
        });
        return;
      }

      else{
        this._subscription.emitTriggerTimestampCtaObserver(true);
        return;
      }
    }
  }

  setTimestamp(date){
    if(this.isSlotsRequired){
      this.fetchSlots(new Date(this.timestampInputDetails.inputValue).getTime());
    }
    else{
      this._journey.setTimestampDetails(new Date(this.timestampInputDetails.inputValue).getTime());
    }
  }


  startIsSlotRequiredSubscription(){
    
    this.isSlotRequiredSubscription = this._subscription.fetchIsSlotRequired().subscribe(res=>{
      this.isSlotsRequired = res;    
      this.timestampInputDetails.showTime = !res;
    })
  }


  fetchSlots(selectedDate){

   let slotsDto = {
      ssoId : localStorage.getItem('ssoId'),
      priceMapId : this._journey.getPriceMapId() ? this._journey.getPriceMapId() : 0,
      zoneId : this._journey.getPickupLocationZoneId(),
      isScheduled : !this._createRide.isRentalRide(),
      isPremium : this._createRide.getSubCategory() ? (this._createRide.getSubCategory().split("_")[0] == 'PREMIUM' ? true : false) : true,
      dateTime: new Date(this.timestampInputDetails.inputValue).getTime()
    }
    
    this._loader.show();
    this._journeyApi.fetchSlots(slotsDto).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
          this.allSlotsData = res['response']['dateSlots'][0]['timeGroupSlotsResponseList'];
          this.hourDropDownDetails.options =  this.setHours(this.allSlotsData);
         
          if(this.hourDropDownDetails.options.length < 1){
            this._commonSubscription.emitAlert({
              theme: Theme.ERROR,
              title: "No Slots Available!",
              message:  "All slots for the selected dates has been full",
              ctaLabel: 'Ok!'
            })
            this.hourDropDownDetails.isDisabled = true;
          }
          else{
            this.hourDropDownDetails.isDisabled = false;
          }
      }
      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: "Technical Error!",
          message: res['message'],
          ctaLabel: 'Ok!'
        })
      }
    });
  }
  

  setHours(timeSlots){
    let availableSlotsHours = [];
    timeSlots.forEach(timeGroup=>{
      Object.keys(timeGroup.slots).forEach(slot=>{
        if(timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE'){
            let hour = slot.split(":")[0];
            if(availableSlotsHours.length < 1){
              availableSlotsHours.push({label : hour, value: hour});
            }
            else{
              let availableSlotsArr = availableSlotsHours.map(slots=>slots.value);
              if(!availableSlotsArr.includes(hour)){
                availableSlotsHours.push({label : hour, value: hour});
              }
              else{
                return;
              }
            }  
        }
        else{
          return;
        }
      });
    });
    return availableSlotsHours;
  }

  onHoursChange(){
   this.minDropDownDetails.options =  this.setMinutes(this.hourDropDownDetails.selectedValue.value, this.allSlotsData);
   if(this.minDropDownDetails.options.length < 1){
    this.minDropDownDetails.isDisabled = true;
   }
   else{
    this.minDropDownDetails.isDisabled = false;
   } 
  }

  setMinutes(selectedHour, timeSlots){
      let availableSlotsMin = [];

      timeSlots.forEach(timeGroup=>{
        Object.keys(timeGroup.slots).forEach(slot=>{
          if(slot.split(":")[0] == selectedHour && timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE'){
            availableSlotsMin.push({label: slot.split(":")[1], value: slot.split(":")[1]});
          }
          else{
            return;
          }
        })
      });

      return availableSlotsMin;
  }

  handleDatePicker(){
    let startDate = new Date();
    let leadTime = this._common.getIsAdminUser() ? 0 : this.zoneBasedLeadTime[this._journey.getPickupLocationExtraFields().zoneId];
    startDate.setMinutes(startDate.getMinutes() + leadTime);

    if(this._common.getIsAdminUser()){
      return startDate;
    }

    else{
      let extraBuffer = this.getExtraBufferTime(startDate.getMinutes());
      extraBuffer == 0 ? startDate.setHours(startDate.getHours() + 1) : startDate.setHours(startDate.getHours());
      startDate.setMinutes(extraBuffer);
      return startDate;
    }
    
  }


  getExtraBufferTime(minVal){
    for(let i = 0; i < 61 ; i = i+this.timestampInputDetails.stepMinute){
      if (minVal >= i && minVal < i + 15) {
        return i + 15;
    }
    else{
      return 0;
    }
    }
  }

  setMaxDate(){
    let maxDate = new Date();
      //changed the addition of days from 3 to 30 as per ThomasCook requirements
      maxDate.setDate(maxDate.getDate() + 30);
      maxDate.setHours(23, 0, 0, 0);

      return maxDate;
  }
}
