import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryFeatureListService } from 'src/app/app-v2/localization/country-feature-list/country-feature-list.service';
import { CustomDropDown } from 'src/app/app-v2/types/components.type';
import { CustomerDetailsApiResponse } from 'src/app/app-v2/types/container.type';
import { customerDetails, noError } from 'src/app/app-v2/validations/createRide/create-ride-validation';
import { CreateRideValidationService } from 'src/app/app-v2/validations/createRide/create-ride-validation.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsContainerService {
customerDetails: Omit<CustomerDetailsApiResponse, 'ssoId' | 'riderId'> & {isFilled: boolean} = {
  callingCode: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  isFilled: false
}

  defaultCallingCode:CustomDropDown | null= null;

  constructor(private _createRideValidation: CreateRideValidationService,
              private _countryFeatureList: CountryFeatureListService,
              private _loader: NgxSpinnerService
  ) { }

  
  setDefaultCallingCode(){
    this._loader.show();
    this.defaultCallingCode = this._countryFeatureList.getCountryFeatureList()['defaultCallingCode'];
    this._loader.hide();
  }

  getDefaultCallingCode(){
    return this.defaultCallingCode;
  }


  setCustomerDetails(customerDetails){
    this.customerDetails = customerDetails;
  }

  getCustomerDetails(){
    let customerInputData = {
      phoneNumber : [this.customerDetails.callingCode, this.customerDetails.phoneNumber],
      firstName: this.customerDetails.firstName,
      lastName: this.customerDetails.lastName,
      email : this.customerDetails.email
    }

    return (this.customerDetails.isFilled) ? customerInputData : null
  }


  async checkValidation(field: string, value = null){
    switch(field){
      case 'phoneNumber':
        var x = await this._createRideValidation.validatePhoneNumber(this.customerDetails.phoneNumber, value);
        return x;
        
      case 'firstName':
        var x = await this._createRideValidation.validateName(this.customerDetails.firstName);
        return x;  
    

      case 'email':
        var x = await this._createRideValidation.validateEmail(this.customerDetails.email);
        return x;  
    }
  }



  validatePhoneNumber<InfoType>(value){

    return {
      isSuccess: false,
      isError: false,
      message: ''
    }
  }
 
  clearCustomerDetails(){
    this.customerDetails = {
      callingCode: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      isFilled: false
    }
  }

  getCustomerDetailsToRequestRide(){
    return this.customerDetails;
  }

}
