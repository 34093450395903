<div class="container-fluid header-nav sticky-top" *ngIf='appService.isValid'>
    <!-- <div class="row justify-content-space-between"> -->
    <div id="mySidenav" class="sidenav" [ngStyle]="{'width': !collapse?'60px':'201px'}" style="top:0px"  *ngIf='appService.isValid'>
      <!-- <a class="closebtn close" (click)="closeNav()">&times;</a> -->

      <div class="container" style="overflow: hidden;">
        <div class="row" style="width: 500px;">
          <div class="col-6" style="height: 50px;">
            <!-- <span class="tile">Symphony</span> -->

            <span style="font-size:15px;cursor:pointer;color: white;"   (click)="openNav()"
            *ngIf='appService.isValid'> <img src="../../../../assets/hamburger.png" style="height: 20px;margin-top: 7px;position: fixed;"> </span>
            &nbsp;&nbsp;
            <span *ngIf="collapse">
              <img src="../../../../assets/BlusmartWhite.png" alt="" width='120px' style="margin-left: 25px;margin-top: 6px;">
            </span>

          </div>

        </div>

      </div>

      <div class="list-group" id="list-tab" role="tablist" >
        <a  class="list-group-item list-group-item-action" *ngIf="isAccountSetupEnabled"
        id="list-user-list" data-toggle="list" role="tab" aria-controls="home" routerLink="/setup"
        routerLinkActive="active" ><i class="fas fa-check-circle"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Account Setup </span></a>

        <a  class="list-group-item list-group-item-action"
        id="list-user-list" data-toggle="list" role="tab" aria-controls="home" [routerLink]="['/users']"
        routerLinkActive="active" ><i class="fas fa-users"></i><span *ngIf="collapse" style="margin-left: 10px;">Users </span></a>

        <a class="list-group-item list-group-item-action"
        id="list-home-list" data-toggle="list" role="tab" aria-controls="home" [routerLink]="['/rides/liverides']"
        routerLinkActive="active" ><i class="fas fa-satellite-dish"></i><span *ngIf="collapse" style="margin-left: 10px;">Live Rides </span></a>

        <a class="list-group-item list-group-item-action"
        id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
        [routerLink]="['/rides/schedulerides']" routerLinkActive="active" ><i class="fas fa-calendar-check"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Scheduled Rides </span></a>

        <a class="list-group-item list-group-item-action" *ngIf="!isRecurringRentalsEnabled"
        id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
        [routerLink]="['/rides/recurringrides']" routerLinkActive="active" ><i class="fas fa-car"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Recurring Rentals</span></a>
  
  
        <a  class="list-group-item list-group-item-action"
        id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile"
        [routerLink]="['/rides/pastrides']" routerLinkActive="active" ><i class="fas fa-history"></i><span *ngIf="collapse" style="margin-left: 10px;">Past Rides </span></a>


        <a class="list-group-item list-group-item-action " id="list-home-list" data-toggle="list" role="tab"
        aria-controls="home" routerLink='/payments' routerLinkActive="active" ><i class="fas fa-credit-card"></i><span *ngIf="collapse" style="margin-left: 10px;">Payments</span></a>

        <a class="list-group-item list-group-item-action " id="list-home-list" data-toggle="list" role="tab"
        aria-controls="home" routerLink='/map' routerLinkActive="active" ><i class="fas fa-map-marked-alt"></i><span *ngIf="collapse" style="margin-left: 10px;">Live Map</span></a>

        <a class="list-group-item list-group-item-action " id="list-home-list" data-toggle="list" role="tab"
        aria-controls="home" routerLink='/reports' routerLinkActive="active" ><i class="fas fa-chart-bar"></i><span *ngIf="collapse" style="margin-left: 10px;">Reports</span></a>

        <!-- <a class="list-group-item list-group-item-action " id="list-home-list" data-toggle="list" role="tab"
        aria-controls="home" routerLink='/help' routerLinkActive="active" ><i class="fas fa-question-circle"></i><span *ngIf="collapse" style="margin-left: 10px;">Help</span></a> -->


      </div>

    </div>

    <div class='d-flex justify-content-between'>
      <!-- city dropdown -->
    <span [ngClass]="{dropDownCollapse: collapse, dropDownNoCollapse: !collapse}">
      <!-- <p-dropdown [options]="cities" [(ngModel)]="selectedCity" (onChange)="onCityChange()" class="cityDropDown">

        <ng-template let-item pTemplate="selectedItem">
          <span class="selectedItem">{{item.label}}</span>
        </ng-template>

        <ng-template let-allCities pTemplate="item">
            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                <div style="font-size:14px;float:left;margin-top:4px;">{{allCities.label}}</div>
                <div style="float:right;margin-top:4px;"*ngIf="allCities.value == selectedCity"><img src="../../../../assets/tickIcon.svg" /></div>
            </div>
        </ng-template>
      </p-dropdown> -->


      <p-multiSelect [options]="cities" [(ngModel)]="selectedCityMulti" defaultLabel="Select City" optionLabel="label" (onChange)="onServiceRegionChange()">
        <ng-template let-value pTemplate="selectedItems">
            <div class="flex align-items-center gap-2" *ngFor="let option of selectedCityMulti">
                <div *ngIf="selectedCityMulti.length == 1">{{ option.label }}</div>
            </div>
            <div *ngIf="selectedCityMulti.length > 1">{{selectedCityMulti.length}} items Selected</div>
            <div *ngIf="!selectedCityMulti || selectedCityMulti.length === 0">Select City</div>

        </ng-template>
        <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
                <span>{{country.label}}</span>
            </div>
        </ng-template>
    </p-multiSelect>



    </span>
      <div class='d-flex justify-content-around'>

        <span style="font-size:15px;cursor:pointer;color: white;" class="col-xs-4 ml-3 mb-1" (click)="openNav()"
          *ngIf='appService.isValid'> <img src="../../../../assets/hamburger.png" style="height: 20px;"> </span>
        <!-- <span style="font-size:20px;cursor:pointer" class="col-xs-4 ml-3"(click)="openNav()"
             *ngIf='!appService.isValid'> </span> -->
        <div class='ml-3'>
          <!-- <img src="../../../../assets/logoBlue.png" alt="" width='70' style="margin-left: 15px;"> -->
        </div>


      </div>

      <!-- <div>
          <a class="userName" style="position:fixed; color: blue;" aria-posinset="right">Shivam</a>
        </div>
        <div>
          <button class="help" style="position:fixed; color: blue;" aria-posinset="right">Help</button>
        </div> -->

        <div style="margin-top: auto; margin-bottom: auto;position: absolute; margin-left: 50%;">
        <img src="{{headerImgURL}}" alt="" width='80px' height="30px" *ngIf="headerImgURL != null">
        <p *ngIf="headerImgURL == null" style="font-size: 1rem; font-weight: 400; color: #495057;">{{businessAccountCode}}</p>
      </div>

      <div style="margin-top: auto; margin-bottom: auto; display: flex; flex-direction: row;">
        <!-- Wallet Balance Section -->
        <div style="display: flex; flex-direction: column; text-align: right; margin-top: auto; margin-bottom: auto; margin-right: 30px;" *ngIf="allowWallet">
          <sup style="color: #334D6E; margin-top: 10px; font-size: 13px;">Total Wallet Balance</sup>
          <div style="display: flex; flex-direction: row; align-items: center; position: relative;">
            <!-- Wallet Balance -->
            <div *ngIf="lowWalletBalance" class="wallet-balance-box" 
                 (mouseenter)="showToolTip()" >
              <i class="fa fa-info-circle" style="margin-right: 4px;"></i>
              Low Balance
            </div>

            <!-- Tooltip Box -->
            <div *ngIf="tooltipVisible" class="tooltipStyle" >
              <span style="margin: 0; font-size: 14px; font-weight: bold;color: #000000;float: right; " (click)="hideTooltip()"><i class="fa fa-times"></i></span>
              <p style="margin: 0; font-size: 14px; font-weight: bold;color: #c7254e; "> <i class="fa fa-info-circle"></i> <br/>Low Balance</p>
              <p style="margin: 10px 0;color: #3e4958;">You have low balance, kindly recharge your wallet to enjoy our services.</p>
              <button style="background-color: #007BFF; color: #FFF; border: none; padding: 8px 16px; border-radius: 4px; cursor: pointer;width: 100%;" (click)="openModal()">Recharge Now</button>
            </div>
          <div style="color: #3E4958; margin-right: 10px;">{{ walletBalance | currencySymbol }}</div>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;text-align: right;margin-top: auto; margin-bottom: auto;margin-right:30px ;" *ngIf="allowPostpaid">
          <sup style="color:#334D6E;margin-top: 10px;font-size: 13px;">Total Postpaid Balance</sup>
          <div style="color: #3E4958;" > {{walletBalance | currencySymbol}}</div>
        </div>
        <p-dropdown [options]="settingList"  pInputText name="setting"  [(ngModel)]="option" [placeholder]="name" [showClear]="true" (onChange)="redirectTo()">
        <ng-template let-item pTemplate="option">
          <i class="{{item.value.icon}}"></i><span style="padding-left: 4px;font-size: 13px !important;">{{item.label}}</span>
          
        </ng-template>
        <ng-template let-object pTemplate="item">
           <i class="{{object.value.icon}}"></i><span style="padding-left: 4px;font-size: 13px !important;">{{object.label}}</span>
          </ng-template>
          <ng-template let-object pTemplate="item">
            <i class="{{object.value.icon}}"></i><span style="padding-left: 4px; font-size: 13px !important;">{{object.label}}</span>
          </ng-template>
        </p-dropdown>
      </div>

    </div>




  </div>
