export const featureList = {'IN' : {
    liveRide: {
        rentalPackageClassic: true,
        rentalPackagePremium: true
    },
    scheduledRide: {
        rentalPackageClassic: true,
        rentalPackagePremium: true
    },
    pastRide: {
        rentalPackageClassic: true,
        rentalPackagePremium: true
    },
    createRide:{
        tncExpand : true
    }

},

'AE' : {
    liveRide: {
        rentalPackageClassic: false,
        rentalPackagePremium: true
    },
    scheduledRide: {
        rentalPackageClassic: false,
        rentalPackagePremium: true
    },
    pastRide: {
        rentalPackageClassic: false,
        rentalPackagePremium: true
    },
    createRide:{
        tncExpand : false
    }
}
}