import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {API_URL_ADD_USER,API_URL_UPDATE_USER,API_URL_FETCH_USERS,API_URL_DELETE_USERS,
        API_URL_FETCH_ACCOUNT_DETAILS,API_URL_CREATE_BUSSINESS_ACCOUNT,API_URL_UPDATE_BUSINESS_ACCOUNT,
        API_URL_GET_BUSINESS_ACCOUNTS,API_URL_GET_ALL_ROLE,API_URL_UPLOAD_USER_CSV,API_URL_STATES_CONFIG,
        API_URL_GET_ALL_USER_FOR_BUSINESS_ACCOUNT,API_URL_GET_GST_DETAILS,API_URL_UPDATE_GST_DETAILS,API_URL_UPLOAD_IMAGE, API_URL_USER_ADD_WINDOW, API_URL_ACTION_ON_GST_DETAILS, API_URL_VERIFY_USER, API_URL_SEND_OTP, API_URL_VERIFY_OTP, API_URL_GET_COUNTRY_CODES, API_URL_UPDATE_ACCOUNT_DETAILS, API_URL_FETCH_ALL_RECURRING_USERS} from '../../config/apiRouteConfig';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private _http: HttpClient) { }

  addNewUser(data: any):Observable<any> {
    return this._http.post(`${API_URL_ADD_USER}`,data);
  }
  updateUser(data: any):Observable<any> {
    return this._http.post(`${API_URL_UPDATE_USER}`,data);
  }
  deleteUsers(data):Observable<any> {
    return this._http.post(`${API_URL_DELETE_USERS}`,data);
  }
  fetchAllUsers():Observable<any> {
    return this._http.get(`${API_URL_FETCH_USERS}`);
  }
  getBussinessAccountDetails(accountId: any){
    return this._http.get(`${API_URL_FETCH_ACCOUNT_DETAILS}/${accountId}/getaccount`);
  }
  createBusinessAccount(accountDetails: any){
    return this._http.post(`${API_URL_CREATE_BUSSINESS_ACCOUNT}`,accountDetails)
  }
  updateBusinessAccount(accountDetails: any,accountId: string){
    return this._http.put(`${API_URL_UPDATE_BUSINESS_ACCOUNT}`+accountId+"/update",accountDetails)
  }
  getAllBusinessAccount(){
    return this._http.get(`${API_URL_GET_BUSINESS_ACCOUNTS}`)
  }

  updateBusinessAccountSetting(settingDto: { organisationName: string; gstNo: string; },id: any){
    let param = "?organisationName="+settingDto.organisationName
    if(settingDto.gstNo){
      param = param+"&gstNo="+settingDto.gstNo
    }
    return this._http.post(`${API_URL_UPDATE_BUSINESS_ACCOUNT}${id}/edit`+param,settingDto);
  }
  getAllRoles(){
    return this._http.get(`${API_URL_GET_ALL_ROLE}`);
  }
  getUsersForBusinessAccount(businessCode: string){
    return this._http.get(`${API_URL_GET_ALL_USER_FOR_BUSINESS_ACCOUNT}`+businessCode+"/all")
  }
  uploadUserCSV(body: any){
    return this._http.post(`${API_URL_UPLOAD_USER_CSV}`,body);
  }
  getGSTDetails(){
    return this._http.get(API_URL_GET_GST_DETAILS)
  }
  actionOnGSTDetails(data: any){
    return this._http.post(API_URL_ACTION_ON_GST_DETAILS,data)
  }
  updateGSTDetails(body: any){
    return this._http.post(API_URL_UPDATE_GST_DETAILS,body)
  }

  getStateConfig(){
    return this._http.get(API_URL_STATES_CONFIG)
  }
  uploadFile(file: any){
    return this._http.post(API_URL_UPLOAD_IMAGE,file)
  }

  getUserInputFields():Observable<any>{
    return this._http.get(API_URL_USER_ADD_WINDOW)
  }

  verifyUser(userName: { username: string; }){
    return this._http.post(API_URL_VERIFY_USER, userName)
  }

  sendOtp(body: { username: string | undefined; notificationChannel: string; resendOtp: boolean; }){
    return this._http.post(API_URL_SEND_OTP, body)
  }


  verifyOtp(body: { username: string | undefined; otp: string; }){
    return this._http.post(API_URL_VERIFY_OTP, body)
  }

  getCountryCodes():Observable<any>{
    return this._http.get(API_URL_GET_COUNTRY_CODES);
    
  }


  fetchRecurringUsers(userName){
    return this._http.get(`${API_URL_FETCH_ALL_RECURRING_USERS}?name=${userName}`)
  }
}
