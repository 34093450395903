<div class="main-container">
    <div class="title-container">
    <h1 class="title">Create Ride</h1>
    <h3 class="caption">Choose ride type to proceed</h3>
    </div>

    <div class="ride-types-container">
        <card type="container" [isActive]="rideType.isActive" 
        *ngFor="let rideType of rideTypes; index as i"  (click)="setSelectedRideType(i)">

            <div container class="ride-type-container">
                <img [src]="rideType.url" alt="" class="ride-preview">
                <span>{{rideType.name}}</span>
            </div>
        </card>
    </div>
</div>