import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './main/login/login.component';
import { FormsModule } from '@angular/forms';
import { AppService } from './services/app.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from "@angular/common";

import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import {ButtonModule} from 'primeng/button';
import {RadioButtonModule} from 'primeng/radiobutton';
import { StepsModule } from 'primeng/steps';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {  MessageService } from 'primeng/api';
import {InputTextModule} from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';

import { AppInterceptor } from './app.interceptor';
import { GlobalErrorHandler } from './guards/global-error-handler';
import { CookieService } from 'ngx-cookie-service';
import { ResetPasswordComponent } from './main/reset-password/reset-password.component';
import { TncComponent } from './main/container/rides/tnc/tnc.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderModule } from './main/header/header/header.module';
import { RideService } from './services/ride/ride.service';
import { RiderService } from './services/rider/rider.service';
import { PaymentService } from './services/payment/payment.service';
import { VehicleService } from './services/vehicle/vehicle.service';
import { DriverService } from './services/driver/driver.service';
import { HelpModule } from './main/container/help/help.module';
import { MultiFactorComponent } from './main/multi-factor/multi-factor.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { PaymentsModule } from './main/container/payments/payments.module';
import { AppV2Module } from './app-v2/app-v2.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    TncComponent,
    MultiFactorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogModule,
    ButtonModule,
    RadioButtonModule,
    StepsModule,
    ProgressSpinnerModule,
    InputTextModule,
    CheckboxModule,
    NgxSpinnerModule,
    ListboxModule,
    HeaderModule,
    HelpModule,
    NgxOtpInputModule,
    AppV2Module
  ],
  providers: [ {
    provide:HTTP_INTERCEPTORS,
    useClass:AppInterceptor,
    multi:true
  },
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
  CookieService,VehicleService,DriverService,
  AppService,MessageService, RideService, RiderService,PaymentService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
