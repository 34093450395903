<div class="main-container">

    <div class="location-container">
        <add-location-container></add-location-container>
        <br>
        <additional-details-container></additional-details-container>
        <br>

        <div class="location-cta-container">
            <custom-button [buttonDetails]="locationContainerCta" (onCustomButtonClick)="submitLocationDetails()"></custom-button>
        </div>   
        
    </div>

    
    <div class="map-container">
        <custom-map [agmCoordinates]="agmCoordinates" [isDropRequired]="isDropRequired" [isVisible]="isShowMapMarkers"></custom-map>
    </div>
    
    

</div>