export const featureList = {
    
    defaultActiveRideTypes : [
        {name: 'Scheduled Ride', code: 'SCHEDULED_RIDE', isActive: false, isRecurring: false, url: "assets/scheduled_ride.png", packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: true},
        {name: 'Rental Rides', code: 'SCHEDULED_RENTALS', isActive: false, isRecurring: false, url: "assets/scheduled_rentals.png", packageRequired: true, isDropRequired: false, isAirportRide: false, slotsRequired: true },
        {name: 'Airport Ride', code: 'SCHEDULED_RIDE', isActive: false, isRecurring: false, url: "assets/scheduled_ride.png", packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: true},
        // {name: 'Shared Rides', code: 'SHARED_RIDE', isActive: false, isRecurring: false, url: "assets/shared_rides.png",  packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: true},
        // {name: 'Recurring Rentals', code: 'RECURRING_RENTALS', isActive: false, isRecurring: true, url: "assets/recurring_rentals.png",  packageRequired: true, isDropRequired: false, isAirportRide: false, slotsRequired: true},
      ],

    carVariants : [ {carUrl : 'assets/premium.png', 
        carLabel: 'Audi E-Tron',
        category: 'PREMIUM',
        extraPoints: [
          {url: 'assets/luggage.svg', label: 'More luggage Space'},
          {url: 'assets/comfort.svg', label: 'Lavish comfort'},
          {url: 'assets/leg.svg', label: 'More leg space'}
          ],
         priceMapId: 0,
         country: 'AE',
         slotsRequired: true,
         isActive: false,
         extraInfo: ['Switch to classic and enjoy an economical ride ']}
       ],
    
    defaultCoordinates: {lat: 25.197525, lng: 55.274288},
    
    defaultPaymentModes:{
      'CASH' : {isActive: false, iconUrl: 'assets/cash_icon.svg', desc: 'pay at the end of the ride', title: 'Cash', code: 'CASH'},
      'PREPAID':{isActive: true, iconUrl: 'assets/card_icon.svg', desc: 'pay in advance seamlessly', title: 'PrePaid', code: 'PREPAID'},
      'POST_PAID':{isActive: false, iconUrl: 'assets/cash_icon.svg', desc: 'pay in advance seamlessly', title: 'PostPaid', code: 'POSTPAID'},
    },

    defaultCallingCode: {
      label: 'UAE',
      value: '+971',
      flag: "🇦🇪",
      limit: 9
    },

    defaultCurrency : {
      code: 'AED',
      type: 'POSTFIX'
    },

    zoneIdBasedLeadTime : {
      3 : 30 
    }
}