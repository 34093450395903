import { Injectable } from '@angular/core';
import { CreateRideSteps, RideType } from 'src/app/app-v2/types/container.type';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_URL_GET_COUNTRY_CODES } from 'src/app/config/apiRouteConfig';
import { CommonService } from '../../common.service';
import { CreateRideRequestApiDto } from 'src/app/app-v2/types/apis.type';
import { CustomerDetailsContainerService } from './customer-details-container.service';
import { JourneyDetailsContainerService } from './journey-details-container.service';

@Injectable({
  providedIn: 'root'
})
export class CreateRideService {

  constructor(private _customer: CustomerDetailsContainerService,
              private _journey: JourneyDetailsContainerService) { }

  createRideAllSteps = {
        CUSTOMER_DETAILS: {label: 'Customer Details', isComplete: false, isActive: false, caption: 'Enter the rider details'},
        JOURNEY_DETAILS: {label: 'Journey Details', isComplete: false, isActive: false, caption: 'Enter pickup/drop and other details regarding your ride'},
        WEEK_SCHEDULE: {label: 'Week Schedule', isComplete: false, isActive: false, caption: 'Set recurring week schedule'},
        PAYMENT_DETAILS: {label: 'Payment Details', isComplete: false, isActive: false, caption: 'Review the details and proceed to pay'}
        }


  isAirportRide = false;     

  createRideApiDto: CreateRideRequestApiDto | null = {
    callingCode: '',
    dropLat: null,
    dropLocation: '',
    dropLong: null,
    email: '',
    firstName: '',
    lastName: '',
    map: '',
    otherFlags: null,
    paymentMode: 'BUSINESS_ACCOUNT',
    phoneNumber: '',
    pickupLat: null,
    pickupLocation: '',
    pickupLong: null,
    pickupNotes: null,
    platform: 'WEB',
    priceMapId: null,
    rideType: '',
    startTime: null,
    stops: [],
    subCategory: null,
    packageCode: null
  };


     getCreateRideNavigationSteps(selectedRideType:RideType){
      let stepTitles = Object.keys(this.createRideAllSteps);
      let createRideSteps = [];
      
      if(!selectedRideType.isRecurring){
        let newStepTitles = stepTitles.filter(title=>title != 'WEEK_SCHEDULE');
        createRideSteps = newStepTitles.map(steps=>this.createRideAllSteps[steps]);
      }
      else{
        createRideSteps = stepTitles.map(steps=>this.createRideAllSteps[steps]);
      }
      return createRideSteps;
     } 


     setSelectedRideType(rideType){
      this.createRideApiDto.rideType = rideType;
     }

     getSelectedRideType(){
      return this.createRideApiDto.rideType;
     }

     isRentalRide(){
      return this.createRideApiDto.rideType.split("_")[1] == 'RENTALS'  ? true : false;
     }

     setPaymentMode(paymentMode){
      this.createRideApiDto.paymentMode = paymentMode;
     }

     setCreateRideRequestApiDto(){
      let customerDetails = this._customer.getCustomerDetailsToRequestRide();
      let journeyDetails = this._journey.getJourneyDetailsToRequestRide();
      
      this.createRideApiDto.callingCode = customerDetails.callingCode;
      this.createRideApiDto.dropLat = journeyDetails.dropLat;
      this.createRideApiDto.dropLocation = journeyDetails.dropLocation;
      this.createRideApiDto.dropLong = journeyDetails.dropLong;
      this.createRideApiDto.email = customerDetails.email;
      this.createRideApiDto.firstName = customerDetails.firstName;
      this.createRideApiDto.lastName = customerDetails.lastName;
      this.createRideApiDto.map = journeyDetails.map;
      this.createRideApiDto.otherFlags = journeyDetails.otherFlags;
      this.createRideApiDto.phoneNumber = customerDetails.phoneNumber;
      this.createRideApiDto.pickupLat = journeyDetails.pickupLat;
      this.createRideApiDto.pickupLocation = journeyDetails.pickupLocation;
      this.createRideApiDto.pickupLong = journeyDetails.pickupLong;
      this.createRideApiDto.pickupNotes = journeyDetails.pickupNotes;
      this.createRideApiDto.platform = journeyDetails.platform;
      this.createRideApiDto.priceMapId = journeyDetails.priceMapId;
      this.createRideApiDto.rideType = this.createRideApiDto.rideType;
      this.createRideApiDto.startTime = journeyDetails.startTime;
      this.createRideApiDto.stops = journeyDetails.stops;
      this.createRideApiDto.packageCode = journeyDetails.packageCode;
      this.createRideApiDto.subCategory = journeyDetails.subCategory;

      this.createRideApiDto.dropLocation != null ? this.sendDropLocationToStop() : null; 
     }


     clearCreateRideAllSteps(){
      this.createRideAllSteps = {
        CUSTOMER_DETAILS: {label: 'Customer Details', isComplete: false, isActive: false, caption: 'Enter the rider details'},
        JOURNEY_DETAILS: {label: 'Journey Details', isComplete: false, isActive: false, caption: 'Enter pickup/drop and other details regarding your ride'},
        WEEK_SCHEDULE: {label: 'Week Schedule', isComplete: false, isActive: false, caption: 'Set recurring week schedule'},
        PAYMENT_DETAILS: {label: 'Payment Details', isComplete: false, isActive: false, caption: 'Review the details and proceed to pay'}
        };
     }

     clearRideRequestDto(){
      this.createRideApiDto= {
        callingCode: '',
        dropLat: null,
        dropLocation: '',
        dropLong: null,
        email: '',
        firstName: '',
        lastName: '',
        map: '',
        otherFlags: null,
        paymentMode: 'BUSINESS_ACCOUNT',
        phoneNumber: '',
        pickupLat: null,
        pickupLocation: '',
        pickupLong: null,
        pickupNotes: null,
        platform: 'WEB',
        priceMapId: null,
        rideType: '',
        startTime: null,
        stops: [],
        subCategory: null,
        packageCode: null
      };

      this._customer.clearCustomerDetails();
      this._journey.clearJourneyDetails();
     }

     sendDropLocationToStop(){
      let newDropLocationDto = {
        lat: this.createRideApiDto.dropLat,
        lng: this.createRideApiDto.dropLong,
        place: this.createRideApiDto.dropLocation,
        position: this.createRideApiDto.stops.length + 1
      };
      this.createRideApiDto.stops.push(newDropLocationDto);
     }

     getCreateRideRequestApiDto(){
      return this.createRideApiDto;
     }

     setSubCategory(category){
      this.createRideApiDto.subCategory = category;
     }

     getSubCategory(){
      return this.createRideApiDto.subCategory;
     }


     // ALTER VALUE OF createRideAllSteps
     alterCreateRideStepData(activeStep, field, value){
      Object.keys(this.createRideAllSteps).forEach(step=>{
        if(this.createRideAllSteps[step]['label'] == activeStep){
          this.createRideAllSteps[step][field] = value;
        }
        else{
          return 
        }
      })
     }

}
