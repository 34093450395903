import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/select-ride-type-container.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CustomButton } from 'src/app/app-v2/types/components.type';
import { JourneyDetailsStep } from 'src/app/app-v2/types/container.type';


@Component({
  selector: 'location-container',
  templateUrl: './location-container.component.html',
  styleUrls: ['./location-container.component.scss']
})
export class LocationContainerComponent implements OnInit, OnDestroy{
  mapCoordinatesSubscription: Subscription;
  addLocationValidationSubscription : Subscription;


  constructor(private _rideType: SelectRideTypeContainerService,
              private _subscription: CreateRideSubscriptionService,
              private _common: CommonService){}
  
  ngOnInit(): void {
    this.startMapCoordinatesSubscription();
    this.startAddLocationValidationStatusObserver();
  }

  isDropRequired = this._rideType.getSelectedRideTypeDetails().isDropRequired;
  locationContainerCta: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: "",
    rightUrl: "",
    disabled:false
  }

  isShowMapMarkers = {
    pick: false,
    drop: false,
    stops: false,
  }

  agmCoordinates = {
    pick : this._common.getDefaultAgmCoordinates(),
    stops: [],
    destination : this._common.getDefaultAgmCoordinates(),
  }


  async submitLocationDetails(){
    this._subscription.emitAddLocationValidationObserver();
    // this._subscription.emitAdditionalDetailsValidationObserver();

    

    // let additionalDetailsPromise = new Promise<boolean>(resolve=>{
    //   this._subscription.fetchAdditionalDetailsValidationStatusObserver().subscribe(res=>{
    //     resolve(res);
    //   })
    // });

    
  }

  startMapCoordinatesSubscription(){
    this.mapCoordinatesSubscription = this._subscription.fetchMapCoordinatesObserver().subscribe(res=>{
      this.getAgmCoordinates(res);
    })
  }


  getAgmCoordinates(event){
    switch(event.type){
      case 'PICKUP':
        this.agmCoordinates = {
          pick: {
            lat: event.coordinates.lat == null ?  this.agmCoordinates.pick.lat : event.coordinates.lat,
            lng: event.coordinates.lng == null ?  this.agmCoordinates.pick.lng : event.coordinates.lng  
          },
          destination : this.agmCoordinates.destination,
          stops: this.agmCoordinates.stops
        }

        
        this.isShowMapMarkers = {
          pick: event.coordinates.lat == null || event.coordinates.lat == 0 ? false : true,
          drop: this.isShowMapMarkers.drop,
          stops:this.isShowMapMarkers.stops,
        }
        
        return 

      case 'DROP':
        this.agmCoordinates = {
          pick: this.agmCoordinates.pick,
          destination : {
            lat: event.coordinates.lat == null ?  this.agmCoordinates.destination.lat : event.coordinates.lat,
            lng: event.coordinates.lng == null ?  this.agmCoordinates.destination.lng : event.coordinates.lng
          },
          stops: this.agmCoordinates.stops
        }

        this.isShowMapMarkers = {
          pick: this.isShowMapMarkers.pick,
          drop: event.coordinates.lat == null || event.coordinates.lat == 0 ? false : true,
          stops:this.isShowMapMarkers.stops,
        }
          return 

      case 'STOPS':
        this.agmCoordinates = {
          pick: this.agmCoordinates.pick,
          destination : this.agmCoordinates.destination,
          stops: event.coordinates.map((coordinate,index)=>{

            this.isShowMapMarkers = {
              pick: this.isShowMapMarkers.pick,
              drop: this.isShowMapMarkers.drop,
              stops: coordinate.inputValue.lat == null || coordinate.inputValue.lat == 0 ? false : true,
            }

            return ({
              location: {
                lat: coordinate.inputValue.lat == null ? this.agmCoordinates.stops[index].lat : coordinate.inputValue.lat,
                lng: coordinate.inputValue.lng == null ? this.agmCoordinates.stops[index].lng : coordinate.inputValue.lng
              },
              stopover: false
            })
        }
      )}
        return 
    }}


    startAddLocationValidationStatusObserver(){
      this.addLocationValidationSubscription = this._subscription.fetchAddLocationValidationStatusObserver().subscribe(res=>{
        console.log(res); 
        this._subscription.emitTriggerLocationCtaObserver(res);
      })
    }
  
ngOnDestroy(): void {
  this.addLocationValidationSubscription.unsubscribe();
  this.mapCoordinatesSubscription.unsubscribe();
}
    
}
